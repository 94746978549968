import React from "react"

const Grid = ({ children, className }) => {
  return (
    <div
      className={`grid grid-cols-6 md:grid-cols-12 grid-x-gaps  ${className}`}
    >
      {children}
    </div>
  )
}

export default Grid
