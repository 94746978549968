import React from "react"

const Menu = ({ fill = "none" }) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 12.5C2 11.9477 2.44772 11.5 3 11.5H21C21.5523 11.5 22 11.9477 22 12.5C22 13.0523 21.5523 13.5 21 13.5H3C2.44772 13.5 2 13.0523 2 12.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 6.5C2 5.94772 2.44772 5.5 3 5.5H21C21.5523 5.5 22 5.94772 22 6.5C22 7.05228 21.5523 7.5 21 7.5H3C2.44772 7.5 2 7.05228 2 6.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 18.5C2 17.9477 2.44772 17.5 3 17.5H21C21.5523 17.5 22 17.9477 22 18.5C22 19.0523 21.5523 19.5 21 19.5H3C2.44772 19.5 2 19.0523 2 18.5Z"
      />
    </svg>
  )
}

export default Menu
