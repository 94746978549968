import React from "react"
import { Link } from "gatsby"
import { AiOutlineArrowRight } from "react-icons/ai"

const ButtonLink = ({
  text = "Button",
  to = "/",
  title = "",
  arrow = false,
  className = "",
}) => {
  const isExternal = to && to.split("/")[0] !== ""

  return !isExternal ? (
    <Link
      className={` text-alabaster-white bg-storm-blue rounded px-6 py-3 text-lg leading-5  hover:bg-dark-grey-blue   focus:bg-dark-grey-blue tracking-wider font-normal flex items-center ${className} `}
      to={to}
      activeClassName="bg-dark-grey-blue"
    >
      {text}
      {arrow ? <AiOutlineArrowRight className="ml-2" /> : null}
    </Link>
  ) : (
    <a
      href={to}
      target="_blank"
      rel="noopener noreferrer"
      className={`text-alabaster-white bg-storm-blue rounded px-6 py-3 text-lg leading-5  hover:bg-dark-grey-blue   focus:bg-dark-grey-blue tracking-wider font-normal ${className}`}
      title={`${title && `${title} `}(Opens in new window)`}
    >
      {text}
    </a>
  )
}

export default ButtonLink
