import { Link } from "gatsby"
import React from "react"
import { FiFacebook, FiInstagram } from "react-icons/fi"

import FullColorLogo from "../../svgs/FullColorLogo"
import Grid from "../grid/Grid"

const StyledFooterLink = ({
  to = "/",
  children,
  className = "",
  title = "",
}) => {
  const isExternal = to && to.split("/")[0] !== ""
  return isExternal ? (
    <a
      href={to}
      className={`block inlineLink ${className}`}
      target="_blank"
      rel="noopener noreferrer"
      title={`${title && `${title} `}(new window)`}
    >
      {children}
    </a>
  ) : (
    <Link to={to} className=" block inlineLink">
      {children}
    </Link>
  )
}

const BookSession = ({ className }) => (
  <div className={`${className} grid gap-y-2`}>
    <StyledFooterLink
      to="https://artandsciencephysio.janeapp.com/"
      title="Booking website"
    >
      Book a session
    </StyledFooterLink>
    <StyledFooterLink to="/contact">Contact Us</StyledFooterLink>
    <StyledFooterLink to="https://artandsciencephysio.sanity.studio">
      Admin
    </StyledFooterLink>
  </div>
)

const FooterTitle = ({ children }) => (
  <div className="mb-2 md:mb-1">{children}</div>
)

const FooterLgText = ({ children, className = "" }) => (
  <div className={`lg:text-base ${className}`}>{children}</div>
)

const Footer = () => {
  return (
    <footer className="px-3 py-5 text-sm text-brand-grey">
      <Grid>
        <div className="col-span-full grid gap-y-5 gap-x-5 md:grid-flow-col">
          <FullColorLogo className=" h-14 md:h-16 lg:h-20 mb-3 md:mb-0 " />

          <div className="flex">
            <div className="grid gap-y-2">
              <FooterTitle>Address</FooterTitle>
              <FooterLgText>10 Main St. East</FooterLgText>
              <FooterLgText>Smiths Falls, ON</FooterLgText>
            </div>
            <div className="grid gap-y-2 ml-24 md:ml-20 lg:ml-32">
              <FooterTitle>Social Media</FooterTitle>
              <StyledFooterLink
                to={"https://www.facebook.com/artandsciencephysio"}
                className=" no-underline"
                title="Facebook"
              >
                <span className="flex items-center">
                  <FiFacebook className="mr-2" />
                  Facebook
                </span>
              </StyledFooterLink>
              <StyledFooterLink
                to={"https://instagram.com/artandsciencephysio/"}
                className=" no-underline"
                title="Instagram"
              >
                <span className="flex items-center">
                  <FiInstagram className="mr-2" />
                  Instagram
                </span>
              </StyledFooterLink>
            </div>
          </div>

          <BookSession />
        </div>
      </Grid>
      <div className="grid justify-items-center">
        <hr className="  border-brand-light-blue my-4 w-3/4 " />
      </div>
      <div className=" grid gap-y-1 text-center">
        <div>
          © Art + Science Physiotherapy and Pilates, {new Date().getFullYear()}
        </div>
        <div>
          Website by{" "}
          <a
            className="font-normal underline hover:text-button-drop-shadow visited:text-purple"
            href="https://www.borealisweb.ca"
            target="_blank"
            rel="noopener noreferrer"
          >
            Borealis Web Development
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
