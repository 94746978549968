import { Link } from "gatsby"
import React, { useContext, useState } from "react"
import { BiChevronDown } from "react-icons/bi"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"

import { ArtSciContext } from "../../context/ArtSciContext"
import FullLogo from "../../svgs/FullLogo"
import Menu from "../../svgs/Menu"
import ButtonLink from "../ButtonLink"
import Grid from "../grid/Grid"

const NavLink = ({ className, to = "/", children = null }) => (
  <Link
    to={to}
    className={` font-medium hover:border-b-2  border-brand-light-blue ${className} `}
    activeClassName="border-b-2  border-brand-light-blue"
  >
    {children}
  </Link>
)

const dropDownLinks = [
  {
    title: "Home",
    handle: "/",
    isIndented: false,
  },
  {
    title: "Our Team",
    handle: "/team",
    isIndented: false,
  },
  {
    title: "Services",
    handle: "/services",
    isIndented: false,
  },
  {
    title: "Physiotherapy",
    handle: "/services/physiotherapy",
    isIndented: true,
  },
  {
    title: "Registered Massage Therapy",
    handle: "/services/rmt",
    isIndented: true,
  },
  {
    title: "Pilates",
    handle: "/services/pilates",
    isIndented: true,
  },
  {
    title: "Contact",
    handle: "/contact",
    isIndented: false,
  },
  // {
  //   title: "Blog",
  //   handle: "/blog",
  //   isIndented: false,
  // },
]

// Link component for main nav menu dropdown
const DropDownNavLink = ({
  to = "/",
  children = null,
  toggleNav = () => console.log("Toggle Nav Open"),
  isIndented = false,
}) => (
  <Link
    to={to}
    className={`font-normal px-5  ${
      isIndented ? "pl-14" : ""
    } py-5 hover:bg-light-grey-blue  `}
    activeClassName="bg-light-grey-blue"
    onClick={() => toggleNav()}
  >
    {children}
  </Link>
)

// Link component for services menu dropdown
const DropLink = ({ children, to = "/" }) => (
  <Link
    className="py-2 px-4 hover:bg-brand-light-blue  font-normal"
    activeClassName="bg-brand-light-blue"
    to={to}
  >
    {children}
  </Link>
)

const Nav = () => {
  const { isNavOpen, toggleNavOpen, setNavOpen } = useContext(ArtSciContext)

  // useScrollPosition for growing and shrinking the navbar on scroll
  // https://github.com/n8tb1t/use-scroll-position
  // https://dev.to/n8tb1t/tracking-scroll-position-with-react-hooks-3bbj
  // https://n8tb1t.github.io/use-scroll-position/navbar/
  // https://www.npmjs.com/package/@n8tb1t/use-scroll-position
  const [isScrolledToTop, setScrolledToTop] = useState(true)

  useScrollPosition(
    ({ currPos }) => {
      const isAtTop = currPos.y > -50
      if (isAtTop !== isScrolledToTop) setScrolledToTop(isAtTop)
    },
    [isScrolledToTop],
    null,
    false,
    300
  )

  const handleClick = () => {
    toggleNavOpen()
  }
  return (
    <div className="sticky top-0 z-30  ">
      <nav
        className={`py-5 px-3 ${
          isScrolledToTop ? "" : "lg:py-3 shadow-sm"
        }  bg-alabaster-white `}
      >
        <Grid>
          <Link
            to="/"
            className={`col-span-3  lg:col-span-2 transform ease-in  duration-75 ${
              isScrolledToTop ? "" : "lg:scale-90 "
            }`}
            onClick={() => setNavOpen(false)}
          >
            <FullLogo className={`h-16 lg:h-20 `} />
          </Link>
          <div className="col-span-8  hidden lg:grid grid-flow-col justify-center gap-x-14 items-center font-medium text-charcoal">
            <NavLink to="/team">Our Team</NavLink>
            <div className=" relative group">
              <NavLink to="/services" className="flex items-center">
                Services&nbsp;
                <BiChevronDown />
              </NavLink>
              <div
                className={` hidden group-hover:grid absolute whitespace-nowrap bg-light-grey-blue  `}
              >
                <div className="h-4  bg-alabaster-white"></div>
                <div className="grid shadow-md">
                  <DropLink to="/services/physiotherapy">
                    Physiotherapy
                  </DropLink>
                  <DropLink to="/services/rmt">
                    Registered Massage Therapy
                  </DropLink>
                  <DropLink to="/services/pilates">Pilates</DropLink>
                </div>
              </div>
            </div>
            <NavLink to="/contact">Contact</NavLink>
            {/* <NavLink to="/blog">Blog</NavLink> */}
          </div>
          <div className="col-span-7 lg:col-span-2 hidden md:grid justify-end items-center">
            <ButtonLink
              text={<span>Book&nbsp;a&nbsp;Session</span>}
              to="https://artandsciencephysio.janeapp.com"
              title=""
            />
          </div>
          <div className="col-span-3 md:col-span-2  flex lg:hidden items-center justify-end text-dark-grey-blue ">
            <button
              className="flex  cursor-pointer"
              onClick={() => handleClick()}
            >
              <span className="mr-2">Menu</span>
              <Menu fill="#4E6777" />
            </button>
          </div>
        </Grid>
      </nav>
      <div className={`${isNavOpen ? "relative lg:hidden" : "hidden"} `}>
        <nav className="absolute top-0 left-0 right-0 bg-alabaster-white z-30 grid font-bold ">
          {dropDownLinks.map((link, index) => (
            <DropDownNavLink
              to={`${link.handle}`}
              key={`navDropdown-${index}-${link.title}`}
              toggleNav={toggleNavOpen}
              isIndented={link.isIndented}
            >
              {link.title}
            </DropDownNavLink>
          ))}
          <a
            href="https://artandsciencephysio.janeapp.com/"
            target="_blank"
            rel="noopener noreferrer"
            className={`font-normal px-5  py-5 hover:bg-light-grey-blue   `}
            activeClassName="bg-light-grey-blue"
            onClick={() => toggleNavOpen()}
            title="(Opens in new window)"
          >
            Book a session
          </a>
        </nav>
      </div>
    </div>
  )
}

export default Nav
