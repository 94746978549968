import React, { useContext } from "react"
import { ArtSciContext } from "../context/ArtSciContext"
import Footer from "./footer/Footer"
import Nav from "./navbar/Nav"

const Layout = ({ children }) => {
  const { isNavOpen, toggleNavOpen } = useContext(ArtSciContext)

  const handleClick = () => toggleNavOpen()

  return (
    <div className="flex justify-center bg-alabaster-white">
      <div
        className={`relative  min-h-screen flex flex-col text-brand-grey  max-w-large  ${
          isNavOpen ? "h-screen lg:h-auto overflow-hidden lg:overflow-auto" : ""
        }`}
      >
        {/* Overlay for nav dropdown */}
        <div
          onClick={() => handleClick()}
          className={`${
            isNavOpen ? "absolute lg:hidden" : "hidden"
          }  top-0 right-0 bottom-0 left-0 bg-brand-grey z-30 bg-opacity-75`}
        ></div>
        {/* End of overlay for nav dropdown */}
        <Nav />
        <main className="px-3 md:px-5  bg-alabaster-white  flex-grow py-10 md:py-16 lg:py-28">
          {children}
        </main>
        <Footer />
      </div>
    </div>
  )
}

export default Layout
