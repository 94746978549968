import React from "react"

const FullLogo = ({ className = "" }) => {
  return (
    <svg
      viewBox="0 0 180 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M55.5784 32.1649C55.6636 32.3255 55.7113 32.5031 55.7179 32.6845C55.7179 33.0109 55.5182 33.4163 55.1243 33.9005C54.811 34.2569 54.5299 34.6401 54.2845 35.0457C53.4071 35.3821 52.4636 35.5133 51.527 35.4293C51.2936 35.3114 50.9444 34.9405 50.4793 34.3167C50.1146 33.8071 49.7653 33.5532 49.4316 33.555C49.1966 33.5629 48.968 33.6334 48.7696 33.759C47.5128 34.8719 45.8781 35.4688 44.1957 35.4293C43.2085 35.4443 42.2341 35.2054 41.3672 34.7356C40.1799 34.0882 39.5863 32.9565 39.5863 31.3406C39.5859 31.109 39.5977 30.8774 39.6219 30.647C40.6194 28.799 42.2836 27.6682 44.6143 27.2547C44.7966 27.2094 45.6693 27.0126 47.2322 26.6644C48.0914 26.4973 48.9247 26.2184 49.7106 25.8347C49.6887 24.8427 49.6541 23.8607 49.6067 22.8886C49.489 21.8005 49.0842 21.0061 48.3839 20.5002C47.7091 20.152 46.8483 20.1284 45.8015 20.4294C45.2435 21.124 44.6845 21.8068 44.1246 22.4778C43.4261 23.2885 42.7167 23.6938 41.9963 23.6938C41.8552 23.6942 41.7142 23.6824 41.5751 23.6585C41.0205 23.4098 40.5309 23.0375 40.1444 22.5703C40.0989 22.4129 40.0759 22.2499 40.076 22.0861C40.076 21.3462 40.4234 20.696 41.1237 20.1411C41.3316 20.0032 42.0173 19.6097 43.1808 18.9605C44.5916 18.7351 46.0149 18.5961 47.4428 18.5442C48.0275 18.5426 48.6112 18.589 49.1881 18.683L51.0045 19.48C51.9802 20.6389 52.469 22.1804 52.4708 24.1046C52.4708 24.7303 52.4061 25.6969 52.2766 27.0044C52.1508 28.3129 52.0851 29.2786 52.0851 29.9016C52.076 30.6595 52.1466 31.4162 52.2957 32.1595C53.0617 32.9239 53.576 33.3047 53.8304 33.3047C54.0486 33.3003 54.2604 33.2301 54.4377 33.1034L55.5784 32.1649ZM49.5602 28.0654L49.2456 27.6165C46.9897 27.894 45.3027 28.4698 44.1848 29.3439C43.5775 30.16 43.3121 31.1829 43.3805 32.4505C43.663 32.8635 44.0374 33.2061 44.4748 33.4516C44.8557 33.5876 45.2573 33.6575 45.662 33.6584C46.9185 33.6584 48.1632 32.9447 49.396 31.5175L49.5602 28.0654Z"
          fill="#5C7E96"
        />
        <path
          d="M70.3805 21.8522C69.9155 22.5957 69.1942 22.9666 68.2167 22.9648C68.0183 22.9646 67.8201 22.9528 67.6231 22.9294C66.8213 22.5714 65.9525 22.386 65.0735 22.3854C64.5597 22.3754 64.049 22.4679 63.5717 22.6574C62.8422 23.3502 62.4775 24.3567 62.4775 25.677C62.4775 26.0506 62.5249 26.6989 62.6197 27.622C62.6811 28.2448 62.7039 28.8707 62.6881 29.4963C62.7118 29.6767 62.7237 29.8584 62.7237 30.0404C62.7237 30.2471 62.7046 30.5844 62.6717 31.0469C62.6389 31.5093 62.6197 31.863 62.6197 32.0888C62.6197 33.0382 62.9316 33.6611 63.5608 33.9631C63.3994 33.9386 63.7359 33.9168 64.5729 33.8923C64.7333 33.8755 64.8951 33.906 65.0381 33.98C65.1811 34.054 65.2991 34.1683 65.3772 34.3085C65.5176 35.072 65.0754 35.4547 64.0505 35.4565C63.394 35.3333 62.7269 35.275 62.059 35.2824C61.7115 35.2824 61.2164 35.3096 60.5763 35.3695C59.9362 35.4293 59.452 35.4565 59.1264 35.4565C58.7414 35.4582 58.3567 35.4345 57.9748 35.3858C57.8601 35.3736 57.7518 35.3269 57.6644 35.2521C57.5769 35.1773 57.5144 35.0778 57.4851 34.9669C57.4146 34.7228 57.44 34.4612 57.5562 34.2351C57.9976 34.0719 58.4535 33.8978 58.924 33.7128C59.3945 33.5278 59.5915 33.1443 59.5149 32.5621C59.4438 32.0885 59.409 31.6101 59.4109 31.1312C59.4109 30.6452 59.452 29.9071 59.534 28.9169C59.6161 27.9267 59.6571 27.2012 59.6571 26.7406C59.6903 25.4516 59.4147 24.1732 58.8529 23.011C58.2702 22.6166 57.9584 22.4071 57.9091 22.3826C57.762 22.2723 57.6418 22.1303 57.5575 21.9673C57.4732 21.8044 57.427 21.6246 57.4222 21.4414C57.4234 21.3129 57.4343 21.1847 57.455 21.0578C57.7997 20.6172 58.4088 20.1991 59.2824 19.8038C60.012 19.5428 60.7059 19.1919 61.3477 18.7592C61.421 18.7165 61.5036 18.6922 61.5884 18.6884C62.0936 18.6884 62.3918 19.024 62.483 19.695V20.9463C62.483 21.4305 62.658 21.6862 63.0109 21.708C64.5064 19.9053 66.3647 19.0031 68.586 19.0013C68.8596 19.0013 69.1468 19.0013 69.4286 19.0366C70.2219 19.5671 70.6185 20.2282 70.6185 21.0143C70.6229 21.3107 70.5401 21.6019 70.3805 21.8522Z"
          fill="#5C7E96"
        />
        <path
          d="M83.0544 33.2041C83.0517 33.4572 82.9906 33.7062 82.8758 33.9321C82.761 34.1581 82.5956 34.3548 82.3924 34.5071C81.9492 34.7574 81.517 35.0104 81.0985 35.2606C80.3457 35.5683 79.5378 35.7201 78.724 35.7068C77.8593 35.7405 77.002 35.5372 76.2456 35.1192C75.3374 34.45 74.8778 33.4081 74.8778 32.0017C74.8778 31.6535 74.9252 30.9834 75.02 29.9914C75.106 29.3258 75.1289 28.6536 75.0884 27.9838C74.9243 27.4043 74.9024 26.3788 75.02 24.8989C75.1349 23.5388 75.0301 22.5114 74.7055 21.8168C74.6031 21.6753 74.4656 21.5627 74.3065 21.4897C74.1473 21.4168 73.9719 21.3861 73.7973 21.4006C73.6321 21.4077 73.468 21.4314 73.3076 21.4713C73.1588 21.5087 73.0067 21.5315 72.8535 21.5393C72.3884 21.5393 72.1422 21.2673 72.1204 20.7232C72.2845 20.1411 72.8435 19.4701 73.7973 18.7102C74.7511 17.9503 75.2982 17.2557 75.4386 16.6264C75.6465 16.4451 75.8672 15.8375 76.1006 14.8038C76.2647 14.0122 76.6723 13.6014 77.3234 13.5797C78.021 13.7184 78.4039 14.2026 78.4751 15.0377C78.186 15.6295 78.0541 16.2851 78.0921 16.942C78.1032 17.6094 78.0203 18.2751 77.8459 18.9196C77.9864 19.21 78.2116 19.4515 78.4922 19.6126C78.7728 19.7737 79.0957 19.847 79.4188 19.8228C79.6822 19.8117 79.9446 19.7826 80.2039 19.7357C80.4633 19.6887 80.7256 19.6596 80.989 19.6487C81.2949 19.6303 81.6003 19.691 81.8755 19.825C82.1507 19.959 82.3863 20.1616 82.5593 20.4131C82.3276 21.153 81.7459 21.523 80.814 21.523H79.2082C78.7159 21.4861 78.2277 21.6347 77.8404 21.9392C77.865 22.3772 77.876 22.8641 77.876 23.3946C77.876 23.993 77.8413 24.8871 77.772 26.0768C77.7027 27.2665 77.6754 28.1579 77.69 28.7509C77.6611 30.0284 77.8384 31.3023 78.2152 32.524C78.4477 32.8695 78.6118 33.1007 78.7048 33.2149C78.7824 33.3209 78.8813 33.4095 78.9953 33.475C79.1093 33.5406 79.2359 33.5818 79.3669 33.5958C79.8816 33.618 80.3948 33.525 80.8687 33.3238C81.3139 33.1405 81.7841 33.0241 82.2638 32.9783C82.5444 32.9711 82.8204 33.0499 83.0544 33.2041Z"
          fill="#5C7E96"
        />
        <path
          d="M98.1711 24.9425C98.4447 25.9381 98.3106 26.7596 97.7526 27.4098C97.0231 27.7725 95.9061 27.8759 94.4015 27.7199C94.4699 28.4163 94.5055 28.925 94.5055 29.2487C94.5055 30.3586 94.117 31.0759 93.3401 31.4005C92.8753 31.4752 92.3987 31.4089 91.9723 31.2101C91.7754 31.1365 91.6048 31.0065 91.4821 30.8366C91.3594 30.6666 91.2901 30.4643 91.283 30.2552L91.4581 27.6165C90.4806 27.709 89.8632 27.7553 89.6061 27.7553C88.49 27.7553 87.7687 27.3735 87.4423 26.61C87.3438 26.15 87.4055 25.6704 87.6173 25.2499C87.6999 25.0588 87.8357 24.8953 88.0088 24.7786C88.1818 24.6619 88.3849 24.597 88.5939 24.5915L91.1435 24.7303C91.1571 23.3832 91.3325 22.0425 91.666 20.7368C92.6927 20.4594 93.5198 20.5981 94.1471 21.153C94.5119 21.8785 94.6158 22.9666 94.459 24.4174C95.7123 24.4522 96.9577 24.6284 98.1711 24.9425Z"
          fill="#5C7E96"
        />
        <path
          d="M40.6395 55.7202C41.0412 56.3351 41.6105 56.8238 42.2808 57.1293C42.9268 57.4284 43.6308 57.5835 44.3434 57.5836C44.7534 57.5807 45.1607 57.5184 45.5526 57.3986C45.9553 57.2833 46.3348 57.0992 46.6741 56.8546C47.007 56.6115 47.2863 56.3032 47.4948 55.9487C47.7136 55.5681 47.8242 55.1356 47.8149 54.6974C47.8337 54.4099 47.7858 54.122 47.6748 53.856C47.5638 53.5899 47.3927 53.3528 47.1747 53.1631C46.696 52.7842 46.1582 52.4856 45.5827 52.279C44.948 52.0423 44.2586 51.8138 43.5091 51.5935C42.7798 51.3813 42.0831 51.0711 41.4383 50.6713C40.7947 50.2703 40.2505 49.7301 39.8462 49.0908C39.4194 48.427 39.2061 47.5311 39.2061 46.4031C39.2153 45.8479 39.3275 45.2992 39.5371 44.7845C39.7707 44.1928 40.1288 43.6576 40.5875 43.2149C41.1173 42.7036 41.7415 42.299 42.4258 42.0234C43.2869 41.6799 44.2092 41.5142 45.1368 41.5364C46.0631 41.5336 46.9849 41.6665 47.8723 41.9309C48.7829 42.2171 49.5837 42.7733 50.1675 43.525L48.2526 45.266C47.9379 44.7944 47.5039 44.4135 46.9942 44.1615C46.4195 43.8769 45.7842 43.7341 45.1422 43.7453C44.5837 43.7296 44.0272 43.8172 43.5009 44.0038C43.1085 44.1477 42.7513 44.3731 42.4532 44.6648C42.2084 44.9112 42.0215 45.2085 41.9061 45.5353C41.8064 45.8113 41.7528 46.1017 41.7474 46.3949C41.7259 46.7061 41.772 47.0182 41.8824 47.3102C41.9929 47.6021 42.1651 47.867 42.3875 48.087C42.8522 48.5039 43.3925 48.8289 43.9796 49.0445C44.6575 49.3049 45.3498 49.5265 46.0532 49.7083C46.7752 49.8927 47.4709 50.1669 48.124 50.5244C48.7545 50.8749 49.2984 51.361 49.7161 51.9471C50.1428 52.5456 50.3562 53.368 50.3562 54.4144C50.3691 55.1927 50.2045 55.9637 49.8747 56.6696C49.5701 57.3199 49.1289 57.8978 48.5808 58.3643C48.0162 58.834 47.3662 59.1914 46.6659 59.4171C45.9018 59.6679 45.1016 59.7929 44.2969 59.7871C43.2084 59.793 42.1286 59.5927 41.1155 59.1968C40.1559 58.8385 39.3235 58.2075 38.7219 57.3823L40.6395 55.7202Z"
          fill="#5C7E96"
        />
        <path
          d="M67.3359 45.7339C66.8423 45.096 66.1976 44.5895 65.4593 44.2595C64.7245 43.9213 63.9246 43.7458 63.1149 43.7454C62.1938 43.7298 61.2805 43.9155 60.4395 44.2894C59.6579 44.6447 58.9599 45.1591 58.3906 45.7992C57.8113 46.457 57.3638 47.2187 57.072 48.0435C56.7533 48.9292 56.5941 49.8639 56.6015 50.8046C56.5962 51.6907 56.7471 52.5709 57.0474 53.4052C57.3295 54.2006 57.7654 54.9335 58.3304 55.5625C58.8999 56.1924 59.5973 56.6949 60.3766 57.0369C61.2393 57.4091 62.1718 57.5945 63.1122 57.5809C64.0299 57.6014 64.938 57.3909 65.752 56.9689C66.5149 56.55 67.1702 55.9616 67.6669 55.2496L69.6666 56.7458C69.4527 57.0178 69.2215 57.2758 68.9745 57.5184C68.5816 57.9058 68.1425 58.2438 67.6669 58.5249C67.0506 58.8957 66.3922 59.1925 65.7055 59.409C64.8471 59.6762 63.9513 59.8048 63.052 59.7898C61.7628 59.8099 60.4857 59.5412 59.3152 59.0037C57.7525 58.2694 56.4308 57.1102 55.5028 55.6603C54.5748 54.2103 54.0784 52.5287 54.0711 50.81C54.0577 49.5399 54.2841 48.2785 54.7386 47.0914C55.1626 45.9949 55.8046 44.9948 56.6261 44.1507C57.4366 43.3149 58.4154 42.6586 59.4985 42.2247C60.6723 41.7569 61.927 41.5231 63.1915 41.5365C64.3556 41.538 65.5083 41.7634 66.5863 42.2002C67.6683 42.6167 68.6145 43.3206 69.3219 44.235L67.3359 45.7339Z"
          fill="#5C7E96"
        />
        <path
          d="M73.8739 41.9799H76.2429V59.3491H73.8739V41.9799Z"
          fill="#5C7E96"
        />
        <path
          d="M82.0668 41.9799H93.3428V44.186H84.4331V49.3547H92.7355V51.5636H84.4331V57.1348H93.786V59.341H82.0668V41.9799Z"
          fill="#5C7E96"
        />
        <path
          d="M98.4802 41.9799H101.591L111.212 56.2588H111.261V41.9799H113.63V59.3491H110.621L100.89 45.0729H100.841V59.3518H98.4802V41.9799Z"
          fill="#5C7E96"
        />
        <path
          d="M131.608 45.7339C131.115 45.0961 130.471 44.5895 129.734 44.2595C128.999 43.9215 128.199 43.7461 127.39 43.7453C126.468 43.7293 125.553 43.9151 124.711 44.2894C123.931 44.6447 123.234 45.1591 122.665 45.7992C122.084 46.4561 121.636 47.218 121.344 48.0435C121.028 48.9297 120.869 49.8641 120.876 50.8046C120.871 51.6904 121.021 52.5704 121.319 53.4052C121.603 54.2001 122.038 54.9327 122.602 55.5624C123.173 56.1922 123.871 56.6947 124.651 57.0368C125.514 57.4094 126.446 57.5948 127.387 57.5809C128.305 57.601 129.213 57.3905 130.027 56.9688C130.789 56.5492 131.444 55.961 131.942 55.2496L133.939 56.7458C133.727 57.0185 133.496 57.2767 133.249 57.5183C132.855 57.9045 132.416 58.2424 131.942 58.5249C131.325 58.8947 130.666 59.1914 129.98 59.409C129.122 59.6762 128.226 59.8047 127.327 59.7898C126.038 59.8101 124.76 59.5414 123.59 59.0036C121.457 58.0202 119.796 56.2462 118.961 54.0608C118.558 53.0239 118.349 51.922 118.346 50.81C118.332 49.5398 118.559 48.2785 119.013 47.0913C119.43 45.996 120.063 44.995 120.876 44.1479C121.686 43.3115 122.665 42.6552 123.749 42.222C124.934 41.7505 126.201 41.5175 127.477 41.5364C128.64 41.5383 129.792 41.7637 130.869 42.2002C131.951 42.6171 132.897 43.3208 133.605 44.235L131.608 45.7339Z"
          fill="#5C7E96"
        />
        <path
          d="M138.146 41.9799H149.422V44.186H140.515V49.3547H148.815V51.5636H140.515V57.1348H149.868V59.341H138.146V41.9799Z"
          fill="#5C7E96"
        />
        <path
          d="M18.6866 75.7472L19.0778 70.5786C19.1632 69.2114 19.3194 67.8494 19.5456 66.4981L20.9298 57.5211C21.176 55.7075 21.3894 53.9556 21.5699 52.2654L31.8009 46.7812C30.4687 52.1185 28.1216 57.959 24.3876 64.2783C24.3876 64.2675 20.4155 70.4263 18.6866 75.7472Z"
          fill="#5C7E96"
        />
        <path
          d="M29.8997 19.3549L22.3085 32.9103C22.3414 33.642 22.3714 34.3847 22.3879 35.1518L31.6888 24.3004C31.2043 22.614 30.6066 20.9618 29.8997 19.3549Z"
          fill="#5C7E96"
        />
        <path
          d="M32.4191 27.2357L22.4097 38.8161C22.3741 42.0424 22.1963 45.5435 21.8625 49.3383L32.4847 43.6447C33.6227 37.4015 33.3738 31.9174 32.4191 27.2357Z"
          fill="#5C7E96"
        />
        <path
          d="M21.5343 24.8962C21.7167 26.0551 21.8699 27.2701 21.9939 28.5414L28.6167 16.7162C27.8246 15.2308 26.9354 13.7987 25.955 12.429L21.5343 24.8962Z"
          fill="#5C7E96"
        />
        <path
          d="M19.1982 5.43774C13.8602 1.32098 8.13289 -0.300334 2.01617 0.573797L4.68335 0.864873C4.68335 0.864873 13.4207 1.28108 18.6347 13.9224L19.1982 5.43774Z"
          fill="#5C7E96"
        />
        <path
          d="M21.857 7.71461C21.7339 7.59764 21.6108 7.49154 21.4877 7.37729L20.6288 20.2335L24.1987 10.1683C23.479 9.29504 22.6964 8.47496 21.857 7.71461Z"
          fill="#5C7E96"
        />
        <path
          d="M9.79602 10.862C9.94932 11.7714 10.0162 12.6932 9.99572 13.615L17.2504 17.2983C16.8592 16.183 16.4298 15.0813 15.9401 13.9986L9.79602 10.862Z"
          fill="#5C7E96"
        />
        <path
          d="M17.7237 18.7211L9.94919 14.7739C9.88136 15.5336 9.7727 16.2891 9.62366 17.0372L18.5607 21.7134C18.3282 20.7178 18.0355 19.7167 17.7237 18.7211Z"
          fill="#5C7E96"
        />
        <path
          d="M9.48695 9.52092L15.1879 12.4289C14.7857 11.6455 14.3672 10.9219 13.9459 10.2527L8.8988 7.9921C9.13425 8.48584 9.33089 8.99697 9.48695 9.52092Z"
          fill="#5C7E96"
        />
        <path
          d="M9.40481 18.1117C9.20785 18.9278 8.94797 19.7684 8.65253 20.6308L19.5264 26.4169C19.3458 25.3152 19.1297 24.1971 18.8808 23.0709L9.40481 18.1117Z"
          fill="#5C7E96"
        />
        <path
          d="M19.7261 27.7172L8.28876 21.6318C8.21764 21.825 8.16019 22.0099 8.08086 22.2031C7.72524 23.0926 7.36141 23.9795 6.98663 24.8636L20.1611 31.5664C20.0626 30.3314 19.9176 29.042 19.7261 27.7172Z"
          fill="#5C7E96"
        />
        <path
          d="M6.5982 25.8402C2.54137 35.7313 -1.0942 45.3857 6.46416 56.8002C14.9581 69.6075 17.286 75.7472 17.286 75.7472C16.7006 74.2211 17.8988 64.8306 18.5636 60.5134C19.2283 56.1963 20.2842 43.5903 20.2842 43.5903C20.5526 39.9979 20.5481 36.3905 20.2705 32.7988L6.5982 25.8402Z"
          fill="#5C7E96"
        />
        <path
          d="M7.9851 6.4279L12.7942 8.58239C7.04954 0.98997 0.210632 2.02913 0.210632 2.02913C3.67385 2.57592 6.31641 4.10474 7.9851 6.4279Z"
          fill="#5C7E96"
        />
        <path
          d="M30.5535 67.5563H31.2237V68.3425H31.2428C31.3487 68.1853 31.4789 68.0458 31.6285 67.929C31.7738 67.8213 31.9307 67.7301 32.0963 67.6569C32.256 67.5827 32.4241 67.5279 32.5969 67.4937C32.7592 67.4621 32.924 67.4457 33.0893 67.4448C33.4373 67.4408 33.7829 67.5036 34.107 67.6297C34.4048 67.7479 34.6755 67.9246 34.903 68.1493C35.1289 68.3735 35.3066 68.641 35.4255 68.9355C35.6772 69.571 35.6772 70.2777 35.4255 70.9132C35.3066 71.2077 35.1289 71.4751 34.903 71.6993C34.6755 71.924 34.4048 72.1008 34.107 72.2189C33.7829 72.3451 33.4373 72.4079 33.0893 72.4039C32.924 72.403 32.7592 72.3866 32.5969 72.3549C32.4245 72.3204 32.2565 72.2666 32.0963 72.1944C31.9313 72.1202 31.7745 72.029 31.6285 71.9224C31.4789 71.8056 31.3487 71.6661 31.2428 71.5089H31.2237V75.1977H30.5535V67.5563ZM34.8839 69.9175C34.885 69.6664 34.8424 69.417 34.758 69.1803C34.6782 68.9601 34.5555 68.7576 34.3969 68.5846C34.2382 68.4155 34.0464 68.2804 33.8334 68.1874C33.5987 68.0864 33.345 68.0363 33.0893 68.0405C32.8364 68.036 32.5851 68.0818 32.3502 68.1752C32.1153 68.2687 31.9016 68.4078 31.7216 68.5846C31.551 68.7546 31.417 68.9573 31.3276 69.1803C31.1381 69.6528 31.1381 70.1796 31.3276 70.652C31.417 70.8751 31.551 71.0778 31.7216 71.2478C31.8937 71.4172 32.0971 71.5521 32.3206 71.6449C32.5614 71.7449 32.8202 71.7949 33.0811 71.7918C33.3368 71.7961 33.5905 71.746 33.8252 71.6449C34.0388 71.5529 34.2307 71.4177 34.3887 71.2478C34.5473 71.0748 34.67 70.8723 34.7498 70.652C34.8367 70.4167 34.8821 70.1682 34.8839 69.9175Z"
          fill="#5C7E96"
        />
        <path
          d="M38.7766 64.6592H39.4468V68.318H39.4659C39.5406 68.1884 39.6326 68.0694 39.7395 67.9644C39.8559 67.8578 39.9855 67.7664 40.1252 67.6923C40.2677 67.6138 40.4193 67.5526 40.5765 67.5101C40.7297 67.4673 40.888 67.4453 41.0471 67.4448C41.6571 67.4448 42.1057 67.6026 42.3957 67.9236C42.7088 68.3059 42.8651 68.7917 42.8334 69.2837V72.2761H42.1632V69.6646C42.1891 69.2452 42.0942 68.8271 41.8896 68.4595C41.7063 68.1874 41.3699 68.0405 40.8747 68.0405C40.7829 68.0493 40.6916 68.0629 40.6012 68.0813C40.4239 68.1189 40.2569 68.1941 40.1115 68.3017C39.9186 68.4465 39.7606 68.6324 39.6492 68.8458C39.499 69.1595 39.4295 69.5053 39.4468 69.8523V72.2761H38.7766V64.6592Z"
          fill="#5C7E96"
        />
        <path
          d="M48.2061 71.46L49.6586 67.5563H50.4793L48.1076 73.5057C47.99 73.8307 47.8034 74.1266 47.5605 74.3735C47.4323 74.4874 47.2826 74.5747 47.12 74.6302C46.9575 74.6858 46.7854 74.7086 46.614 74.6972C46.5127 74.6972 46.4088 74.6972 46.3048 74.6972C46.1998 74.6896 46.096 74.6695 45.9957 74.6373L46.0668 73.9899C46.2241 74.0669 46.3977 74.1051 46.5729 74.1014C46.6746 74.1051 46.7759 74.0874 46.8702 74.0494C46.9644 74.0114 47.0496 73.9541 47.12 73.8811C47.2761 73.6964 47.3959 73.4842 47.4729 73.2554L47.8203 72.3468L45.8726 67.5672H46.7124L48.2061 71.46Z"
          fill="#5C7E96"
        />
        <path
          d="M55.8739 68.6771C55.78 68.4901 55.6397 68.33 55.4663 68.2119C55.2827 68.0932 55.0669 68.0334 54.848 68.0406C54.7301 68.041 54.6127 68.0547 54.4979 68.0814C54.386 68.1057 54.2788 68.148 54.1806 68.2065C54.0883 68.2608 54.0098 68.3352 53.9508 68.4241C53.8911 68.5214 53.8616 68.634 53.866 68.7478C53.8593 68.8417 53.8773 68.9356 53.918 69.0204C53.9588 69.1053 54.021 69.1782 54.0985 69.2321C54.3153 69.3604 54.5519 69.4524 54.7988 69.5041L55.4772 69.6646C55.7896 69.7296 56.0754 69.8859 56.2979 70.1134C56.4068 70.225 56.4921 70.3571 56.549 70.5019C56.6058 70.6467 56.633 70.8013 56.6289 70.9567C56.6352 71.1753 56.5858 71.3919 56.4853 71.5864C56.3849 71.7809 56.2367 71.9471 56.0544 72.0694C55.8739 72.1906 55.6728 72.2781 55.4608 72.3278C55.2387 72.3813 55.0109 72.4078 54.7824 72.4067C54.4314 72.4099 54.0837 72.3395 53.762 72.1999C53.4295 72.0472 53.1497 71.8002 52.9578 71.4899L53.535 71.0982C53.6633 71.3065 53.8384 71.4825 54.0465 71.6123C54.2688 71.7437 54.5239 71.8097 54.7824 71.8028C54.9186 71.8023 55.0544 71.7868 55.1872 71.7565C55.315 71.7306 55.4372 71.6828 55.5483 71.6151C55.6508 71.5539 55.7379 71.4701 55.8027 71.3702C55.8671 71.2635 55.9003 71.141 55.8985 71.0166C55.9054 70.9148 55.8835 70.8132 55.8351 70.7232C55.7867 70.6333 55.7139 70.5587 55.6249 70.5079C55.4265 70.3933 55.2116 70.3098 54.9876 70.2603L54.3392 70.108C54.2272 70.0803 54.1167 70.0467 54.0082 70.0074C53.863 69.9579 53.7264 69.8863 53.6034 69.7952C53.4661 69.6973 53.3506 69.5722 53.2641 69.4279C53.1641 69.2584 53.1148 69.064 53.1219 68.8675C53.1169 68.6473 53.1677 68.4293 53.2696 68.2337C53.3628 68.0563 53.498 67.9041 53.6635 67.7903C53.8343 67.6681 54.0249 67.5761 54.2271 67.5183C54.4384 67.4613 54.6565 67.433 54.8754 67.4339C55.1929 67.4301 55.5075 67.4951 55.7973 67.6244C56.0875 67.7594 56.3208 67.9914 56.4565 68.2799L55.8739 68.6771Z"
          fill="#5C7E96"
        />
        <path
          d="M60.6802 65.7473C60.6821 65.813 60.6693 65.8783 60.6428 65.9384C60.6163 65.9986 60.5768 66.0523 60.527 66.0955C60.4376 66.1827 60.3172 66.2316 60.1919 66.2316C60.0666 66.2316 59.9463 66.1827 59.8568 66.0955C59.8091 66.0508 59.7711 65.9968 59.7452 65.9369C59.7192 65.877 59.7058 65.8125 59.7058 65.7473C59.7058 65.6821 59.7192 65.6176 59.7452 65.5577C59.7711 65.4978 59.8091 65.4439 59.8568 65.3991C59.9463 65.3119 60.0666 65.263 60.1919 65.263C60.3172 65.263 60.4376 65.3119 60.527 65.3991C60.5765 65.4426 60.6158 65.4963 60.6423 65.5564C60.6687 65.6165 60.6817 65.6817 60.6802 65.7473ZM60.527 72.2761H59.8568V67.5563H60.527V72.2761Z"
          fill="#5C7E96"
        />
        <path
          d="M68.9635 69.9175C68.967 70.2559 68.9028 70.5915 68.7748 70.905C68.6557 71.2003 68.4774 71.4684 68.2507 71.6929C68.024 71.9174 67.7536 72.0936 67.4562 72.2108C66.8033 72.4575 66.0821 72.4575 65.4292 72.2108C65.1321 72.0913 64.8616 71.9147 64.6331 71.6912C64.4082 71.4662 64.2305 71.199 64.1106 70.905C63.8627 70.2688 63.8627 69.5635 64.1106 68.9274C64.2305 68.6334 64.4082 68.3661 64.6331 68.1412C64.8616 67.9177 65.1321 67.7411 65.4292 67.6216C66.0821 67.3749 66.8033 67.3749 67.4562 67.6216C67.7536 67.7388 68.024 67.915 68.2507 68.1395C68.4774 68.364 68.6557 68.6321 68.7748 68.9274C68.9025 69.2419 68.9667 69.5783 68.9635 69.9175ZM68.2331 69.9175C68.2342 69.6664 68.1917 69.417 68.1073 69.1803C68.0275 68.9601 67.9048 68.7576 67.7462 68.5846C67.5874 68.4155 67.3957 68.2804 67.1827 68.1874C66.7052 67.9917 66.1692 67.9917 65.6918 68.1874C65.4836 68.2805 65.2973 68.4158 65.1447 68.5846C64.9887 68.7586 64.868 68.9609 64.789 69.1803C64.6172 69.656 64.6172 70.1764 64.789 70.652C64.868 70.8715 64.9887 71.0738 65.1447 71.2478C65.2967 71.4172 65.4832 71.5525 65.6918 71.645C66.1692 71.8407 66.7052 71.8407 67.1827 71.645C67.3962 71.553 67.5881 71.4177 67.7462 71.2478C67.9087 71.0731 68.0342 70.8677 68.1155 70.6439C68.1959 70.4102 68.2356 70.1645 68.2331 69.9175Z"
          fill="#5C7E96"
        />
        <path
          d="M74.6617 68.163H73.2939V70.9459C73.2903 71.0898 73.3069 71.2336 73.3432 71.373C73.3689 71.4692 73.4177 71.5579 73.4854 71.6314C73.5464 71.691 73.6218 71.7341 73.7043 71.7565C73.7934 71.7809 73.8854 71.7928 73.9778 71.7919C74.0983 71.7917 74.218 71.7743 74.3335 71.7402C74.4502 71.7095 74.5631 71.6657 74.6699 71.6096L74.7 72.2244C74.415 72.3452 74.1071 72.4037 73.7973 72.3958C73.6618 72.395 73.5269 72.3785 73.3952 72.3468C73.2555 72.3114 73.1256 72.2454 73.0149 72.1537C72.8956 72.0493 72.802 71.919 72.7414 71.7728C72.6585 71.5711 72.6202 71.354 72.6292 71.1363V68.163H71.6252V67.5563H72.6292V66.2261H73.2994V67.5563H74.6672L74.6617 68.163Z"
          fill="#5C7E96"
        />
        <path
          d="M77.6024 64.6592H78.2699V68.318H78.2918C78.3647 68.1872 78.4569 68.068 78.5653 67.9644C78.6808 67.8578 78.8095 67.7664 78.9483 67.6923C79.0909 67.6138 79.2424 67.5526 79.3997 67.5101C79.5538 67.4672 79.713 67.4453 79.8729 67.4448C80.1231 67.4205 80.3755 67.4508 80.6127 67.5335C80.8499 67.6162 81.066 67.7493 81.2462 67.9236C81.5571 68.3069 81.7123 68.7922 81.6811 69.2837V72.2761H81.0109V69.6646C81.0369 69.2452 80.942 68.8271 80.7374 68.4595C80.5568 68.1874 80.2176 68.0405 79.7252 68.0405C79.6333 68.0491 79.542 68.0627 79.4517 68.0813C79.2734 68.1183 79.1053 68.1935 78.9593 68.3017C78.7668 68.447 78.6089 68.6328 78.497 68.8458C78.3468 69.1595 78.2772 69.5053 78.2945 69.8523V72.2761H77.627L77.6024 64.6592Z"
          fill="#5C7E96"
        />
        <path
          d="M85.7325 70.0998C85.7529 70.3299 85.8112 70.555 85.9049 70.7663C86.0843 71.1697 86.4108 71.4905 86.8185 71.664C87.0269 71.7523 87.2514 71.7968 87.4778 71.7946C87.8046 71.8036 88.1269 71.7175 88.4052 71.547C88.6433 71.404 88.8461 71.2097 88.9988 70.9785L89.5158 71.4001C89.2689 71.7265 88.9472 71.9895 88.5775 72.1673C88.2338 72.3219 87.8605 72.4007 87.4833 72.3985C87.1478 72.4013 86.815 72.3385 86.504 72.2135C86.2126 72.0938 85.9485 71.9171 85.7271 71.6939C85.509 71.4657 85.3356 71.1991 85.2155 70.9078C84.9692 70.2713 84.9692 69.5666 85.2155 68.9301C85.3306 68.6375 85.5025 68.3703 85.7216 68.1439C85.942 67.9175 86.2067 67.7382 86.4992 67.6171C86.7918 67.4959 87.1062 67.4354 87.4231 67.4394C87.755 67.4335 88.0843 67.4984 88.3888 67.6298C88.66 67.7477 88.9032 67.921 89.1027 68.1385C89.2979 68.3564 89.4475 68.6107 89.5432 68.8866C89.6461 69.1794 89.6979 69.4876 89.6964 69.7979V70.0998H85.7325ZM88.9578 69.5558C88.9724 69.1542 88.8269 68.763 88.5529 68.4676C88.4029 68.3229 88.2243 68.2109 88.0283 68.1388C87.8324 68.0667 87.6235 68.036 87.4149 68.0487C87.1996 68.0494 86.9866 68.0928 86.7885 68.1766C86.5914 68.2557 86.4106 68.37 86.255 68.5139C86.1048 68.6516 85.9814 68.8158 85.8912 68.9981C85.8004 69.172 85.7535 69.3653 85.7544 69.5612L88.9578 69.5558Z"
          fill="#5C7E96"
        />
        <path
          d="M93.0091 69.028C93.0091 68.8213 93.0091 68.6091 92.9927 68.3942C92.9763 68.1793 92.9763 67.8991 92.9681 67.5563H93.6274V68.435H93.6492C93.6988 68.3028 93.767 68.1782 93.8517 68.065C93.9418 67.9454 94.0487 67.8391 94.169 67.7495C94.3016 67.6504 94.4494 67.5733 94.6067 67.521C94.7885 67.4617 94.979 67.4331 95.1702 67.4366C95.3373 67.4335 95.5039 67.4537 95.6654 67.4965L95.5341 68.1521C95.4094 68.1131 95.2789 68.0956 95.1483 68.1004C94.9197 68.0938 94.693 68.1443 94.4891 68.2473C94.3134 68.3402 94.1581 68.4668 94.0322 68.62C93.8062 68.9024 93.682 69.252 93.6793 69.6129V72.2761H93.0091V69.028Z"
          fill="#5C7E96"
        />
        <path
          d="M101.369 69.3816V69.262C101.369 68.4459 100.961 68.0405 100.152 68.0405C99.6162 68.031 99.0974 68.2257 98.7018 68.5846L98.2969 68.1113C98.7428 67.6542 99.4103 67.4257 100.305 67.4257C100.529 67.4247 100.753 67.4586 100.967 67.5264C101.172 67.5869 101.361 67.6913 101.521 67.8323C101.682 67.9733 101.809 68.1474 101.894 68.3425C101.995 68.5779 102.043 68.8321 102.036 69.0879V71.1961C102.036 71.3756 102.036 71.5661 102.064 71.7646C102.091 71.9632 102.099 72.1265 102.118 72.2543H101.47C101.451 72.1401 101.435 72.0149 101.424 71.8816C101.413 71.7483 101.41 71.6096 101.41 71.4872H101.388C101.224 71.773 100.983 72.0083 100.693 72.1673C100.389 72.314 100.054 72.385 99.7167 72.374C99.5103 72.375 99.3049 72.3466 99.1066 72.2897C98.9167 72.2327 98.7394 72.1404 98.5841 72.0176C98.4335 71.8995 98.3096 71.7512 98.2203 71.5824C98.1262 71.397 98.0793 71.1915 98.0835 70.9839C98.0639 70.6561 98.1705 70.3332 98.3817 70.0808C98.5884 69.8588 98.8467 69.6907 99.134 69.5911C99.4447 69.4821 99.7684 69.4144 100.097 69.3898C100.436 69.3626 100.751 69.349 101.035 69.349L101.369 69.3816ZM101.024 69.9257C100.855 69.9257 100.644 69.9257 100.39 69.9529C100.143 69.9687 99.8981 70.0088 99.6592 70.0726C99.4414 70.1301 99.2376 70.231 99.0601 70.3691C98.9805 70.4355 98.917 70.5189 98.8744 70.6131C98.8318 70.7073 98.8111 70.8099 98.8139 70.9132C98.8092 71.0526 98.8423 71.1907 98.9097 71.3131C98.9733 71.423 99.0624 71.5163 99.1696 71.5851C99.2788 71.6563 99.4003 71.707 99.5279 71.7347C99.6598 71.7652 99.7947 71.7807 99.93 71.781C100.147 71.7854 100.362 71.7446 100.562 71.6613C100.735 71.5858 100.889 71.4734 101.013 71.3321C101.138 71.1916 101.231 71.0266 101.287 70.8479C101.346 70.6566 101.375 70.4576 101.374 70.2576V69.9148L101.024 69.9257Z"
          fill="#5C7E96"
        />
        <path
          d="M105.617 67.5563H106.285V68.3425H106.307C106.409 68.1826 106.537 68.041 106.687 67.9236C106.831 67.8154 106.987 67.7241 107.152 67.6516C107.313 67.5777 107.482 67.5229 107.655 67.4883C107.818 67.4567 107.982 67.4403 108.148 67.4394C108.496 67.4356 108.841 67.4984 109.165 67.6243C109.465 67.7421 109.738 67.9178 109.97 68.1412C110.195 68.3662 110.372 68.6334 110.492 68.9274C110.74 69.5636 110.74 70.2689 110.492 70.9051C110.372 71.199 110.195 71.4663 109.97 71.6912C109.741 71.9147 109.471 72.0913 109.174 72.2108C108.849 72.3367 108.504 72.3995 108.156 72.3958C107.991 72.3949 107.826 72.3785 107.664 72.3468C107.49 72.312 107.321 72.2581 107.16 72.1863C106.996 72.1126 106.84 72.0214 106.695 71.9143C106.544 71.799 106.414 71.6592 106.309 71.5008H106.288V75.1896H105.62L105.617 67.5563ZM109.948 69.9176C109.949 69.6662 109.906 69.4165 109.819 69.1804C109.74 68.9603 109.619 68.7578 109.461 68.5846C109.301 68.4162 109.11 68.2811 108.897 68.1875C108.663 68.0864 108.409 68.0363 108.153 68.0406C107.9 68.036 107.649 68.0819 107.414 68.1753C107.179 68.2687 106.965 68.4079 106.785 68.5846C106.615 68.7552 106.48 68.9577 106.389 69.1804C106.199 69.6528 106.199 70.1796 106.389 70.6521C106.48 70.8747 106.615 71.0772 106.785 71.2478C106.957 71.4179 107.161 71.5528 107.384 71.645C107.625 71.745 107.884 71.795 108.145 71.7919C108.401 71.7961 108.654 71.746 108.889 71.645C109.102 71.5523 109.294 71.4171 109.453 71.2478C109.61 71.0746 109.732 70.8722 109.811 70.6521C109.9 70.4173 109.946 70.1686 109.948 69.9176Z"
          fill="#5C7E96"
        />
        <path
          d="M115.605 71.46L117.055 67.5563H117.875L115.501 73.5057C115.382 73.8301 115.196 74.1258 114.954 74.3735C114.825 74.4874 114.675 74.5748 114.512 74.6303C114.349 74.6859 114.176 74.7086 114.005 74.6972C113.903 74.6972 113.802 74.6972 113.695 74.6972C113.59 74.6894 113.487 74.6693 113.386 74.6373L113.457 73.9899C113.615 74.0668 113.788 74.1051 113.964 74.1014C114.065 74.1054 114.167 74.0879 114.261 74.0499C114.355 74.0119 114.44 73.9543 114.511 73.8811C114.668 73.6966 114.788 73.4844 114.866 73.2554L115.211 72.3468L113.263 67.5672H114.106L115.605 71.46Z"
          fill="#5C7E96"
        />
        <path
          d="M128.552 68.9192H130.834V69.5231H128.552V71.7919H127.945V69.5231H125.664V68.9192H127.945V66.6505H128.552V68.9192Z"
          fill="#5C7E96"
        />
        <path
          d="M139.24 67.5563H139.91V68.3425H139.929C140.035 68.1853 140.166 68.0458 140.315 67.929C140.459 67.8208 140.615 67.7295 140.78 67.6569C140.941 67.5831 141.11 67.5283 141.284 67.4937C141.446 67.4621 141.611 67.4457 141.776 67.4448C142.124 67.4408 142.47 67.5036 142.794 67.6297C143.091 67.7469 143.361 67.9231 143.588 68.1476C143.815 68.3721 143.993 68.6402 144.112 68.9355C144.364 69.571 144.364 70.2777 144.112 70.9132C143.993 71.2085 143.815 71.4765 143.588 71.701C143.361 71.9255 143.091 72.1017 142.794 72.2189C142.47 72.3451 142.124 72.4079 141.776 72.4039C141.611 72.403 141.446 72.3866 141.284 72.3549C141.11 72.3201 140.942 72.2662 140.78 72.1944C140.616 72.1208 140.46 72.0295 140.315 71.9224C140.166 71.8056 140.035 71.6661 139.929 71.5089H139.91V75.1977H139.24V67.5563ZM143.571 69.9175C143.572 69.6661 143.529 69.4165 143.442 69.1803C143.365 68.962 143.244 68.7618 143.086 68.5923C142.928 68.4229 142.736 68.2878 142.523 68.1956C142.288 68.0945 142.034 68.0444 141.779 68.0487C141.518 68.0456 141.259 68.0956 141.018 68.1956C140.795 68.2894 140.592 68.4242 140.419 68.5927C140.249 68.7634 140.114 68.9658 140.022 69.1885C139.837 69.6616 139.837 70.187 140.022 70.6602C140.114 70.8828 140.249 71.0853 140.419 71.2559C140.591 71.4254 140.795 71.5602 141.018 71.6531C141.259 71.7531 141.518 71.8031 141.779 71.8C142.034 71.8042 142.288 71.7542 142.523 71.6531C142.736 71.5604 142.928 71.4252 143.086 71.2559C143.245 71.0835 143.367 70.8808 143.445 70.6602C143.527 70.4215 143.566 70.1699 143.56 69.9175H143.571Z"
          fill="#5C7E96"
        />
        <path
          d="M148.426 65.7473C148.428 65.813 148.415 65.8782 148.389 65.9384C148.362 65.9986 148.323 66.0523 148.273 66.0955C148.183 66.1814 148.064 66.2301 147.939 66.2315C147.815 66.2295 147.696 66.181 147.605 66.0955C147.557 66.0512 147.518 65.9974 147.492 65.9374C147.466 65.8775 147.452 65.8128 147.452 65.7473C147.452 65.6819 147.466 65.6171 147.492 65.5572C147.518 65.4973 147.557 65.4434 147.605 65.3991C147.696 65.3137 147.815 65.2651 147.939 65.2631C148.064 65.2646 148.183 65.3132 148.273 65.3991C148.322 65.4426 148.362 65.4963 148.388 65.5564C148.415 65.6165 148.428 65.6817 148.426 65.7473ZM148.267 72.2761H147.6V67.5563H148.267V72.2761Z"
          fill="#5C7E96"
        />
        <path
          d="M152.735 72.2761H152.067V64.6592H152.735V72.2761Z"
          fill="#5C7E96"
        />
        <path
          d="M159.418 69.3817V69.262C159.418 68.4477 159.012 68.0405 158.2 68.0405C157.665 68.031 157.146 68.2257 156.75 68.5846L156.346 68.1113C156.791 67.6543 157.46 67.4258 158.353 67.4258C158.578 67.4244 158.802 67.4584 159.015 67.5264C159.218 67.5896 159.404 67.6943 159.563 67.8338C159.722 67.9796 159.85 68.1564 159.937 68.3534C160.037 68.5892 160.085 68.8431 160.08 69.0988V71.207C160.08 71.3865 160.08 71.577 160.104 71.7756C160.129 71.9741 160.14 72.1374 160.159 72.2652H159.511C159.491 72.151 159.475 72.0258 159.464 71.8925C159.453 71.7592 159.45 71.6205 159.45 71.4981H159.429C159.265 71.7845 159.024 72.02 158.734 72.1782C158.43 72.3249 158.095 72.3959 157.757 72.3849C157.551 72.3859 157.345 72.3575 157.147 72.3006C156.957 72.243 156.78 72.1508 156.625 72.0285C156.474 71.9104 156.35 71.7621 156.261 71.5933C156.167 71.4079 156.12 71.2024 156.124 70.9948C156.104 70.667 156.211 70.3441 156.422 70.0917C156.628 69.8704 156.886 69.7023 157.172 69.602C157.483 69.4936 157.808 69.4259 158.137 69.4007C158.477 69.3735 158.791 69.3599 159.073 69.3599L159.418 69.3817ZM159.073 69.9257C158.903 69.9257 158.693 69.9257 158.438 69.9529C158.191 69.9687 157.947 70.0088 157.708 70.0726C157.491 70.1299 157.288 70.2308 157.112 70.3691C157.031 70.4348 156.967 70.5181 156.923 70.6124C156.88 70.7067 156.859 70.8096 156.863 70.9132C156.86 71.0524 156.893 71.1901 156.958 71.3131C157.026 71.4242 157.12 71.5174 157.232 71.5851C157.341 71.6563 157.463 71.707 157.59 71.7347C157.722 71.7652 157.857 71.7807 157.992 71.781C158.209 71.7858 158.424 71.745 158.624 71.6613C158.797 71.5858 158.951 71.4734 159.076 71.3321C159.201 71.1923 159.294 71.0271 159.349 70.8479C159.408 70.6567 159.438 70.4576 159.437 70.2576V69.9149L159.073 69.9257Z"
          fill="#5C7E96"
        />
        <path
          d="M166.12 68.163H164.752V70.9459C164.749 71.0899 164.767 71.2337 164.804 71.373C164.83 71.4692 164.878 71.5579 164.946 71.6314C165.007 71.691 165.082 71.7341 165.165 71.7565C165.254 71.7812 165.346 71.7931 165.439 71.7919C165.558 71.7917 165.677 71.7743 165.791 71.7402C165.908 71.71 166.021 71.6661 166.128 71.6096L166.158 72.2244C165.873 72.3452 165.565 72.4037 165.255 72.3958C165.12 72.395 164.985 72.3785 164.853 72.3468C164.713 72.3114 164.584 72.2454 164.473 72.1537C164.354 72.0484 164.261 71.9184 164.199 71.7728C164.119 71.5705 164.082 71.3537 164.09 71.1363V68.163H163.086V67.5563H164.09V66.2261H164.757V67.5563H166.125L166.12 68.163Z"
          fill="#5C7E96"
        />
        <path
          d="M169.525 70.0998C169.546 70.3299 169.604 70.555 169.698 70.7663C169.877 71.1697 170.204 71.4905 170.612 71.664C170.82 71.7523 171.044 71.7968 171.271 71.7946C171.598 71.8036 171.92 71.7175 172.198 71.547C172.436 71.404 172.639 71.2097 172.792 70.9785L173.309 71.4001C173.062 71.7265 172.74 71.9895 172.37 72.1673C172.027 72.3219 171.653 72.4007 171.276 72.3985C170.941 72.4013 170.608 72.3385 170.297 72.2135C170.006 72.0938 169.741 71.9171 169.52 71.6939C169.301 71.4685 169.126 71.2048 169.003 70.9159C168.757 70.2795 168.757 69.5747 169.003 68.9383C169.118 68.6456 169.29 68.3785 169.509 68.1521C169.73 67.9256 169.994 67.7464 170.287 67.6252C170.579 67.504 170.894 67.4436 171.211 67.4475C171.542 67.4416 171.872 67.5066 172.176 67.6379C172.447 67.7559 172.691 67.9292 172.89 68.1466C173.085 68.3646 173.235 68.6189 173.331 68.8947C173.434 69.1876 173.485 69.4958 173.484 69.806V70.0998H169.525ZM172.751 69.5558C172.765 69.1541 172.62 68.763 172.346 68.4676C172.196 68.3229 172.017 68.2109 171.821 68.1388C171.625 68.0666 171.416 68.036 171.208 68.0487C170.993 68.0493 170.78 68.0928 170.581 68.1766C170.38 68.2542 170.194 68.3686 170.034 68.5139C169.884 68.6516 169.761 68.8158 169.67 68.9981C169.58 69.172 169.533 69.3653 169.534 69.5612L172.751 69.5558Z"
          fill="#5C7E96"
        />
        <path
          d="M179.239 68.6771C179.147 68.4903 179.007 68.3301 178.835 68.2119C178.651 68.0936 178.435 68.0338 178.216 68.0406C178.098 68.0409 177.981 68.0546 177.866 68.0814C177.751 68.1018 177.641 68.1404 177.538 68.1956C177.447 68.2507 177.37 68.325 177.311 68.4133C177.25 68.5102 177.22 68.6228 177.223 68.737C177.217 68.8308 177.235 68.9247 177.275 69.0096C177.316 69.0944 177.378 69.1673 177.456 69.2212C177.673 69.3496 177.909 69.4415 178.156 69.4932L178.835 69.6537C179.147 69.7192 179.432 69.8755 179.655 70.1026C179.764 70.2144 179.849 70.3465 179.905 70.4913C179.961 70.6361 179.988 70.7907 179.984 70.9459C179.99 71.175 179.936 71.4019 179.828 71.6042C179.726 71.7852 179.583 71.9406 179.412 72.0585C179.231 72.1792 179.03 72.2667 178.818 72.3169C178.596 72.3703 178.368 72.3968 178.14 72.3958C177.789 72.3994 177.441 72.3289 177.119 72.1891C176.786 72.0365 176.505 71.7895 176.312 71.4791L176.892 71.0873C177.02 71.2962 177.195 71.4723 177.404 71.6015C177.626 71.7328 177.881 71.7989 178.14 71.7919C178.276 71.7911 178.412 71.7756 178.545 71.7457C178.671 71.7193 178.793 71.6714 178.903 71.6042C179.006 71.5438 179.093 71.4599 179.157 71.3594C179.223 71.2532 179.256 71.1303 179.253 71.0057C179.26 70.904 179.238 70.8023 179.19 70.7124C179.141 70.6224 179.069 70.5478 178.98 70.497C178.78 70.3819 178.564 70.2984 178.339 70.2495L177.691 70.0971C177.58 70.0693 177.47 70.0357 177.363 69.9965C177.217 69.9465 177.08 69.875 176.955 69.7843C176.819 69.6856 176.703 69.5608 176.616 69.4171C176.517 69.247 176.468 69.053 176.474 68.8567C176.469 68.6364 176.52 68.4185 176.622 68.2228C176.716 68.0453 176.852 67.8931 177.018 67.7794C177.184 67.6587 177.369 67.5667 177.565 67.5074C177.778 67.4505 177.997 67.4221 178.216 67.4231C178.534 67.4199 178.848 67.4848 179.138 67.6135C179.428 67.7485 179.662 67.9805 179.798 68.2691L179.239 68.6771Z"
          fill="#5C7E96"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="180"
            height="75.625"
            fill="white"
            transform="translate(0 0.1875)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default FullLogo
