import React from "react"

const FullColorLogo = ({ className = "" }) => {
  return (
    <svg
      className={`${className}`}
      viewBox="0 0 200 85"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M61.7538 35.9415C61.8485 36.122 61.9014 36.3216 61.9088 36.5255C61.9088 36.8924 61.6869 37.348 61.2493 37.8923C60.9011 38.2928 60.5889 38.7236 60.3161 39.1795C59.3412 39.5576 58.2929 39.7051 57.2523 39.6106C56.9929 39.4781 56.6049 39.0613 56.0882 38.3601C55.6829 37.7873 55.2948 37.5019 54.924 37.504C54.6629 37.5129 54.4089 37.5921 54.1885 37.7333C52.792 38.9841 50.9757 39.655 49.1064 39.6106C48.0095 39.6275 46.9269 39.3589 45.9635 38.8309C44.6444 38.1032 43.9848 36.8313 43.9848 35.0151C43.9843 34.7547 43.9975 34.4944 44.0243 34.2354C45.1327 32.1583 46.9818 30.8874 49.5714 30.4227C49.7741 30.3717 50.7437 30.1505 52.4803 29.7592C53.4349 29.5714 54.3608 29.2578 55.2341 28.8266C55.2097 27.7116 55.1712 26.6079 55.1185 25.5153C54.9879 24.2923 54.538 23.3995 53.7599 22.8308C53.0101 22.4394 52.0537 22.4129 50.8906 22.7513C50.2705 23.532 49.6495 24.2994 49.0274 25.0536C48.2513 25.9647 47.463 26.4203 46.6626 26.4203C46.5057 26.4208 46.3491 26.4075 46.1945 26.3806C45.5783 26.1011 45.0344 25.6826 44.6049 25.1576C44.5543 24.9806 44.5288 24.7974 44.5289 24.6133C44.5289 23.7817 44.9149 23.0509 45.693 22.4272C45.924 22.2722 46.6859 21.8299 47.9787 21.1002C49.5462 20.8469 51.1277 20.6906 52.7143 20.6324C53.3639 20.6305 54.0125 20.6827 54.6535 20.7883L56.6717 21.6842C57.7558 22.9867 58.2989 24.7193 58.3009 26.882C58.3009 27.5853 58.229 28.6717 58.0851 30.1414C57.9453 31.612 57.8723 32.6975 57.8723 33.3977C57.8623 34.2495 57.9407 35.1 58.1064 35.9354C58.9575 36.7946 59.5289 37.2227 59.8116 37.2227C60.0541 37.2177 60.2894 37.1388 60.4863 36.9964L61.7538 35.9415ZM55.0669 31.3338L54.7173 30.8293C52.2108 31.1412 50.3364 31.7884 49.0942 32.7709C48.4195 33.6881 48.1246 34.8378 48.2006 36.2626C48.5144 36.7268 48.9305 37.1118 49.4164 37.3878C49.8397 37.5406 50.2859 37.6192 50.7356 37.6201C52.1317 37.6201 53.5147 36.8181 54.8845 35.2139L55.0669 31.3338Z"
        fill="#4A4F54"
      />
      <path
        d="M78.2006 24.3504C77.6839 25.1861 76.8825 25.6029 75.7964 25.6009C75.5759 25.6007 75.3557 25.5874 75.1368 25.5612C74.2459 25.1587 73.2805 24.9503 72.304 24.9497C71.733 24.9384 71.1656 25.0424 70.6353 25.2554C69.8247 26.0341 69.4195 27.1654 69.4195 28.6493C69.4195 29.0692 69.4721 29.7979 69.5775 30.8354C69.6457 31.5354 69.6711 32.239 69.6535 32.9421C69.6798 33.1449 69.693 33.3491 69.693 33.5536C69.693 33.786 69.6717 34.1651 69.6353 34.6849C69.5988 35.2047 69.5775 35.6022 69.5775 35.8559C69.5775 36.923 69.924 37.6232 70.6231 37.9626C70.4438 37.9351 70.8176 37.9106 71.7477 37.8831C71.9259 37.8642 72.1057 37.8984 72.2646 37.9816C72.4235 38.0648 72.5545 38.1933 72.6413 38.3509C72.7974 39.2091 72.306 39.6392 71.1672 39.6412C70.4378 39.5027 69.6966 39.4371 68.9544 39.4455C68.5684 39.4455 68.0182 39.4761 67.307 39.5434C66.5957 39.6106 66.0578 39.6412 65.6961 39.6412C65.2682 39.643 64.8408 39.6165 64.4164 39.5617C64.289 39.548 64.1687 39.4956 64.0715 39.4115C63.9743 39.3274 63.9049 39.2156 63.8723 39.0908C63.794 38.8165 63.8223 38.5225 63.9514 38.2684C64.4417 38.0849 64.9483 37.8892 65.4711 37.6813C65.9939 37.4734 66.2128 37.0423 66.1277 36.388C66.0486 35.8556 66.01 35.318 66.0122 34.7797C66.0122 34.2334 66.0577 33.4038 66.1489 32.2908C66.2401 31.1779 66.2857 30.3625 66.2857 29.8448C66.3226 28.396 66.0163 26.9591 65.3921 25.6529C64.7447 25.2095 64.3982 24.9741 64.3435 24.9466C64.18 24.8225 64.0465 24.663 63.9528 24.4798C63.8591 24.2966 63.8077 24.0946 63.8024 23.8887C63.8037 23.7443 63.8159 23.6001 63.8389 23.4576C64.2219 22.9622 64.8987 22.4924 65.8693 22.048C66.68 21.7547 67.451 21.3603 68.1641 20.8739C68.2455 20.826 68.3374 20.7987 68.4316 20.7944C68.9929 20.7944 69.3242 21.1715 69.4255 21.9257V23.3322C69.4255 23.8764 69.6201 24.1639 70.0122 24.1883C71.6738 22.1622 73.7386 21.1481 76.2067 21.1461C76.5106 21.1461 76.8298 21.1461 77.1429 21.1858C78.0243 21.782 78.465 22.525 78.465 23.4086C78.4698 23.7417 78.378 24.069 78.2006 24.3504Z"
        fill="#4A4F54"
      />
      <path
        d="M92.2827 37.1094C92.2796 37.3939 92.2117 37.6739 92.0842 37.9278C91.9567 38.1817 91.7729 38.4028 91.5471 38.574C91.0547 38.8553 90.5744 39.1397 90.1094 39.4209C89.273 39.7668 88.3753 39.9374 87.4711 39.9224C86.5104 39.9603 85.5577 39.7318 84.7173 39.262C83.7082 38.5098 83.1976 37.3388 83.1976 35.758C83.1976 35.3666 83.2502 34.6135 83.3556 33.4985C83.4512 32.7504 83.4766 31.9949 83.4316 31.242C83.2492 30.5907 83.2249 29.438 83.3556 27.7747C83.4833 26.246 83.3668 25.0912 83.0061 24.3105C82.8923 24.1515 82.7396 24.0249 82.5628 23.9429C82.3859 23.8609 82.191 23.8264 81.9969 23.8427C81.8134 23.8508 81.6311 23.8774 81.4529 23.9222C81.2876 23.9642 81.1186 23.9898 80.9483 23.9987C80.4316 23.9987 80.158 23.6929 80.1337 23.0814C80.3161 22.4271 80.9372 21.6729 81.9969 20.8188C83.0567 19.9647 83.6646 19.184 83.8206 18.4767C84.0517 18.2729 84.2968 17.59 84.5562 16.4281C84.7386 15.5384 85.1915 15.0767 85.9149 15.0522C86.69 15.2082 87.1155 15.7524 87.1945 16.6911C86.8733 17.3563 86.7268 18.0931 86.769 18.8314C86.7813 19.5815 86.6892 20.3297 86.4954 21.0542C86.6515 21.3806 86.9018 21.652 87.2135 21.8331C87.5253 22.0142 87.8841 22.0965 88.2431 22.0693C88.5358 22.0569 88.8273 22.0242 89.1155 21.9715C89.4037 21.9186 89.6951 21.8859 89.9878 21.8736C90.3277 21.853 90.667 21.9212 90.9728 22.0718C91.2786 22.2224 91.5403 22.4501 91.7325 22.7328C91.4752 23.5645 90.8288 23.9803 89.7933 23.9803H88.0091C87.4621 23.9388 86.9196 24.1058 86.4893 24.4481C86.5167 24.9404 86.5289 25.4877 86.5289 26.0839C86.5289 26.7566 86.4904 27.7615 86.4134 29.0987C86.3364 30.4358 86.306 31.4377 86.3222 32.1042C86.2901 33.5401 86.4871 34.9719 86.9058 36.3451C87.1641 36.7334 87.3465 36.9933 87.4498 37.1217C87.536 37.2407 87.6459 37.3403 87.7726 37.414C87.8993 37.4877 88.0399 37.534 88.1854 37.5497C88.7574 37.5747 89.3276 37.4702 89.8541 37.244C90.3488 37.038 90.8713 36.9071 91.4042 36.8557C91.716 36.8476 92.0227 36.9362 92.2827 37.1094Z"
        fill="#4A4F54"
      />
      <path
        d="M109.079 27.8236C109.383 28.9427 109.234 29.866 108.614 30.5968C107.803 31.0045 106.562 31.1206 104.891 30.9453C104.967 31.7281 105.006 32.2998 105.006 32.6637C105.006 33.9112 104.574 34.7173 103.711 35.0822C103.195 35.1662 102.665 35.0916 102.191 34.8682C101.973 34.7855 101.783 34.6394 101.647 34.4484C101.51 34.2574 101.433 34.03 101.425 33.795L101.62 30.8292C100.534 30.9331 99.848 30.9851 99.5623 30.9851C98.3222 30.9851 97.5207 30.556 97.158 29.6979C97.0486 29.1808 97.1172 28.6417 97.3526 28.1691C97.4442 27.9544 97.5952 27.7705 97.7875 27.6394C97.9798 27.5083 98.2054 27.4353 98.4377 27.4292L101.27 27.5851C101.286 26.071 101.481 24.5642 101.851 23.0966C102.992 22.7847 103.911 22.9407 104.608 23.5644C105.013 24.3798 105.129 25.6028 104.954 27.2335C106.347 27.2726 107.731 27.4706 109.079 27.8236Z"
        fill="#4A4F54"
      />
      <path
        d="M45.1551 62.417C45.6014 63.1081 46.2339 63.6574 46.9788 64.0008C47.6966 64.337 48.4788 64.5112 49.2706 64.5114C49.726 64.5081 50.1786 64.438 50.614 64.3035C51.0615 64.1739 51.4832 63.9669 51.8602 63.692C52.2301 63.4187 52.5404 63.0722 52.7721 62.6738C53.0151 62.2461 53.1381 61.7599 53.1277 61.2673C53.1486 60.9443 53.0954 60.6207 52.972 60.3216C52.8487 60.0226 52.6586 59.7561 52.4164 59.5429C51.8845 59.117 51.287 58.7813 50.6475 58.5492C49.9423 58.2831 49.1763 58.0263 48.3435 57.7787C47.5332 57.5402 46.759 57.1915 46.0426 56.7421C45.3275 56.2915 44.7228 55.6842 44.2736 54.9657C43.7994 54.2197 43.5623 53.2127 43.5623 51.9448C43.5726 51.3208 43.6973 50.7041 43.9301 50.1256C44.1897 49.4606 44.5877 48.859 45.0973 48.3614C45.686 47.7867 46.3795 47.3319 47.1399 47.0222C48.0966 46.6361 49.1214 46.4499 50.152 46.4749C51.1813 46.4717 52.2055 46.6211 53.1915 46.9182C54.2033 47.2399 55.0931 47.8651 55.7417 48.7099L53.614 50.6668C53.2644 50.1367 52.7822 49.7086 52.2158 49.4254C51.5772 49.1055 50.8714 48.945 50.1581 48.9576C49.5375 48.9399 48.9191 49.0384 48.3344 49.2481C47.8983 49.4099 47.5015 49.6632 47.1702 49.9911C46.8983 50.268 46.6907 50.6022 46.5623 50.9695C46.4516 51.2797 46.392 51.6061 46.3861 51.9357C46.3622 52.2854 46.4134 52.6363 46.5361 52.9644C46.6588 53.2925 46.8502 53.5902 47.0973 53.8375C47.6136 54.306 48.214 54.6713 48.8663 54.9137C49.6195 55.2064 50.3888 55.4555 51.1703 55.6598C51.9725 55.8671 52.7455 56.1753 53.4712 56.577C54.1717 56.9711 54.7761 57.5174 55.2402 58.1761C55.7143 58.8488 55.9514 59.7732 55.9514 60.9493C55.9657 61.824 55.7828 62.6906 55.4165 63.484C55.0779 64.215 54.5877 64.8645 53.9788 65.3889C53.3514 65.9168 52.6291 66.3185 51.8511 66.5722C51.0021 66.8541 50.1129 66.9946 49.2189 66.988C48.0094 66.9947 46.8097 66.7695 45.6839 66.3245C44.6177 65.9218 43.6928 65.2126 43.0244 64.2851L45.1551 62.417Z"
        fill="#5B7E96"
      />
      <path
        d="M74.8177 51.1925C74.2692 50.4755 73.5529 49.9062 72.7326 49.5353C71.9161 49.1552 71.0273 48.958 70.1277 48.9575C69.1043 48.9399 68.0895 49.1487 67.155 49.569C66.2866 49.9683 65.511 50.5464 64.8785 51.2659C64.2348 52.0052 63.7376 52.8614 63.4134 53.7884C63.0593 54.784 62.8823 55.8345 62.8906 56.8918C62.8847 57.8878 63.0524 58.8771 63.386 59.8148C63.6995 60.7088 64.1838 61.5325 64.8116 62.2395C65.4443 62.9475 66.2192 63.5124 67.0851 63.8967C68.0437 64.315 69.0798 64.5235 70.1246 64.5082C71.1444 64.5312 72.1533 64.2945 73.0578 63.8202C73.9054 63.3495 74.6336 62.6882 75.1854 61.8879L77.4073 63.5695C77.1697 63.8752 76.9129 64.1653 76.6383 64.4379C76.2018 64.8733 75.7139 65.2532 75.1854 65.5692C74.5006 65.986 73.7691 66.3196 73.0061 66.5629C72.0524 66.8632 71.057 67.0077 70.0578 66.9909C68.6254 67.0135 67.2064 66.7115 65.9058 66.1073C64.1695 65.282 62.7009 63.9791 61.6698 62.3494C60.6387 60.7197 60.0872 58.8297 60.0791 56.8979C60.0642 55.4703 60.3157 54.0525 60.8207 52.7183C61.2918 51.4859 62.0051 50.3618 62.918 49.413C63.8185 48.4736 64.906 47.7359 66.1095 47.2483C67.4136 46.7225 68.8078 46.4597 70.2128 46.4747C71.5062 46.4764 72.7871 46.7298 73.9848 47.2208C75.187 47.6889 76.2384 48.48 77.0244 49.5078L74.8177 51.1925Z"
        fill="#5B7E96"
      />
      <path
        d="M82.0821 46.9731H84.7143V66.4956H82.0821V46.9731Z"
        fill="#5B7E96"
      />
      <path
        d="M91.1853 46.9731H103.714V49.4528H93.8145V55.2622H103.039V57.7449H93.8145V64.0068H104.207V66.4865H91.1853V46.9731Z"
        fill="#5B7E96"
      />
      <path
        d="M109.422 46.9731H112.878L123.568 63.0222H123.623V46.9731H126.255V66.4956H122.912L112.1 50.4496H112.046V66.4987H109.422V46.9731Z"
        fill="#5B7E96"
      />
      <path
        d="M146.231 51.1926C145.684 50.4758 144.968 49.9064 144.149 49.5354C143.332 49.1556 142.444 48.9584 141.544 48.9576C140.52 48.9396 139.504 49.1483 138.568 49.5691C137.701 49.9685 136.926 50.5466 136.295 51.266C135.649 52.0044 135.151 52.8608 134.827 53.7885C134.475 54.7846 134.299 55.8349 134.307 56.8919C134.302 57.8876 134.468 58.8766 134.799 59.8149C135.114 60.7084 135.598 61.5318 136.225 62.2396C136.859 62.9475 137.635 63.5123 138.502 63.8968C139.46 64.3155 140.496 64.524 141.541 64.5083C142.561 64.5309 143.57 64.2943 144.474 63.8203C145.321 63.3487 146.049 62.6875 146.602 61.888L148.821 63.5696C148.585 63.8762 148.329 64.1663 148.055 64.438C147.617 64.872 147.129 65.2518 146.602 65.5693C145.916 65.985 145.185 66.3185 144.422 66.563C143.469 66.8633 142.473 67.0078 141.474 66.991C140.042 67.0139 138.623 66.7119 137.322 66.1074C134.953 65.002 133.107 63.0081 132.179 60.5518C131.731 59.3864 131.499 58.1478 131.495 56.898C131.481 55.4704 131.732 54.0526 132.237 52.7184C132.7 51.4872 133.404 50.3622 134.307 49.4101C135.207 48.47 136.295 47.7322 137.498 47.2453C138.815 46.7155 140.223 46.4536 141.641 46.4748C142.934 46.4769 144.214 46.7303 145.41 47.2209C146.612 47.6894 147.664 48.4805 148.45 49.5079L146.231 51.1926Z"
        fill="#5B7E96"
      />
      <path
        d="M153.495 46.9731H166.024V49.4528H156.128V55.2622H165.35V57.7449H156.128V64.0068H166.52V66.4865H153.495V46.9731Z"
        fill="#5B7E96"
      />
      <path
        d="M20.7629 84.9265L21.1976 79.1171C21.2925 77.5804 21.466 76.0496 21.7173 74.5308L23.2553 64.4409C23.5289 62.4025 23.766 60.4334 23.9666 58.5337L35.3344 52.3696C33.8541 58.3686 31.2462 64.9331 27.0973 72.0358C27.0973 72.0236 22.6839 78.9459 20.7629 84.9265Z"
        fill="#5B7E96"
      />
      <path
        d="M33.2219 21.5435L24.7872 36.7793C24.8237 37.6017 24.8571 38.4364 24.8754 39.2987L35.2097 27.1021C34.6714 25.2066 34.0073 23.3496 33.2219 21.5435Z"
        fill="#5B7E96"
      />
      <path
        d="M36.0213 30.4014L24.8997 43.4174C24.8602 47.0436 24.6626 50.9787 24.2918 55.244L36.0942 48.8445C37.3587 41.8274 37.0821 35.6634 36.0213 30.4014Z"
        fill="#4A4F54"
      />
      <path
        d="M23.9271 27.7718C24.1298 29.0743 24.3 30.44 24.4378 31.8689L31.7964 18.5777C30.9163 16.9082 29.9284 15.2986 28.839 13.759L23.9271 27.7718Z"
        fill="#B55434"
      />
      <path
        d="M21.3313 5.90105C15.4002 1.27395 9.03646 -0.54835 2.24011 0.434144L5.20364 0.761305C5.20364 0.761305 14.9118 1.22911 20.7052 15.4376L21.3313 5.90105Z"
        fill="#BECCD6"
      />
      <path
        d="M24.2858 8.46019C24.149 8.32872 24.0123 8.20947 23.8755 8.08105L22.9211 22.5311L26.8876 11.2181C26.088 10.2365 25.2184 9.31481 24.2858 8.46019Z"
        fill="#5B7E96"
      />
      <path
        d="M10.8844 11.9978C11.0547 13.0199 11.129 14.0559 11.1063 15.092L19.1671 19.232C18.7324 17.9784 18.2552 16.7401 17.7111 15.5232L10.8844 11.9978Z"
        fill="#BECCD6"
      />
      <path
        d="M19.693 20.831L11.0547 16.3945C10.9793 17.2484 10.8586 18.0976 10.693 18.9384L20.6231 24.1944C20.3647 23.0753 20.0395 21.9501 19.693 20.831Z"
        fill="#BECCD6"
      />
      <path
        d="M10.541 10.4906L16.8754 13.7591C16.4285 12.8785 15.9635 12.0652 15.4954 11.313L9.88751 8.77222C10.1491 9.32717 10.3676 9.90167 10.541 10.4906Z"
        fill="#BECCD6"
      />
      <path
        d="M10.4499 20.1462C10.231 21.0635 9.94228 22.0083 9.61401 22.9775L21.6961 29.481C21.4955 28.2426 21.2554 26.986 20.9788 25.7202L10.4499 20.1462Z"
        fill="#BECCD6"
      />
      <path
        d="M21.9178 30.9425L9.20963 24.1028C9.1306 24.3199 9.06677 24.5278 8.97862 24.7449C8.58349 25.7447 8.17923 26.7414 7.76282 27.7352L22.4011 35.269C22.2917 33.8808 22.1306 32.4316 21.9178 30.9425Z"
        fill="#BECCD6"
      />
      <path
        d="M7.33122 28.8328C2.82362 39.95 -1.2159 50.8013 7.18228 63.6308C16.62 78.0257 19.2066 84.9267 19.2066 84.9267C18.5561 83.2114 19.8874 72.6567 20.626 67.8044C21.3647 62.952 22.5379 48.7833 22.5379 48.7833C22.8362 44.7456 22.8311 40.691 22.5227 36.654L7.33122 28.8328Z"
        fill="#BECCD6"
      />
      <path
        d="M8.87225 7.01388L14.2157 9.43546C7.83273 0.901827 0.233948 2.06981 0.233948 2.06981C4.08197 2.68438 7.01814 4.40273 8.87225 7.01388Z"
        fill="#BECCD6"
      />
      <path
        d="M33.9484 75.7203H34.6931V76.6039H34.7144C34.832 76.4273 34.9767 76.2704 35.143 76.1392C35.3044 76.0182 35.4787 75.9156 35.6627 75.8334C35.8401 75.75 36.0269 75.6884 36.2189 75.65C36.3992 75.6144 36.5824 75.596 36.7661 75.5949C37.1527 75.5904 37.5367 75.661 37.8967 75.8028C38.2276 75.9357 38.5285 76.1343 38.7812 76.3868C39.0322 76.6388 39.2297 76.9394 39.3618 77.2705C39.6414 77.9847 39.6414 78.7791 39.3618 79.4933C39.2297 79.8243 39.0322 80.125 38.7812 80.3769C38.5285 80.6295 38.2276 80.8281 37.8967 80.9609C37.5367 81.1028 37.1527 81.1734 36.7661 81.1688C36.5824 81.1678 36.3992 81.1494 36.2189 81.1138C36.0273 81.075 35.8407 81.0145 35.6627 80.9334C35.4794 80.8499 35.3052 80.7475 35.143 80.6277C34.9767 80.4964 34.832 80.3395 34.7144 80.1629H34.6931V84.309H33.9484V75.7203ZM38.76 78.3742C38.7612 78.092 38.7139 77.8117 38.6202 77.5456C38.5315 77.2981 38.3952 77.0705 38.2189 76.876C38.0425 76.686 37.8295 76.5341 37.5928 76.4296C37.332 76.3161 37.0502 76.2598 36.7661 76.2645C36.485 76.2595 36.2058 76.311 35.9448 76.416C35.6838 76.521 35.4463 76.6774 35.2463 76.876C35.0568 77.0671 34.9079 77.2949 34.8086 77.5456C34.598 78.0767 34.598 78.6688 34.8086 79.1998C34.9079 79.4505 35.0568 79.6783 35.2463 79.8694C35.4376 80.0598 35.6636 80.2114 35.9119 80.3158C36.1795 80.4282 36.467 80.4844 36.7569 80.4809C37.0411 80.4857 37.3229 80.4294 37.5837 80.3158C37.821 80.2124 38.0342 80.0604 38.2098 79.8694C38.3861 79.6749 38.5224 79.4474 38.611 79.1998C38.7076 78.9353 38.758 78.656 38.76 78.3742Z"
        fill="#B55434"
      />
      <path
        d="M43.085 72.4641H43.8297V76.5765H43.851C43.9339 76.4308 44.0362 76.2971 44.1549 76.179C44.2843 76.0592 44.4283 75.9565 44.5835 75.8733C44.7419 75.785 44.9103 75.7162 45.085 75.6684C45.2552 75.6203 45.4311 75.5956 45.6078 75.595C46.2856 75.595 46.7841 75.7724 47.1063 76.1332C47.4542 76.5629 47.6279 77.1089 47.5926 77.662V81.0253H46.8479V78.09C46.8767 77.6186 46.7713 77.1487 46.544 76.7355C46.3403 76.4298 45.9665 76.2646 45.4163 76.2646C45.3143 76.2745 45.2128 76.2898 45.1124 76.3105C44.9154 76.3528 44.7299 76.4373 44.5683 76.5582C44.3539 76.721 44.1784 76.9299 44.0546 77.1697C43.8878 77.5223 43.8105 77.911 43.8297 78.301V81.0253H43.085V72.4641Z"
        fill="#B55434"
      />
      <path
        d="M53.5623 80.1078L55.1763 75.7202H56.0881L53.4529 82.4071C53.3222 82.7724 53.1149 83.105 52.845 83.3825C52.7026 83.5105 52.5362 83.6086 52.3556 83.6711C52.175 83.7335 51.9839 83.7591 51.7933 83.7463C51.6808 83.7463 51.5653 83.7463 51.4498 83.7463C51.3331 83.7378 51.2178 83.7152 51.1064 83.679L51.1854 82.9513C51.3602 83.0378 51.553 83.0808 51.7477 83.0767C51.8607 83.0808 51.9732 83.0609 52.078 83.0182C52.1827 82.9755 52.2773 82.9111 52.3556 82.829C52.5291 82.6215 52.6621 82.3829 52.7477 82.1258L53.1337 81.1046L50.9696 75.7324H51.9027L53.5623 80.1078Z"
        fill="#B55434"
      />
      <path
        d="M62.082 76.98C61.9777 76.7698 61.8218 76.5899 61.6291 76.4572C61.4252 76.3237 61.1854 76.2565 60.9422 76.2645C60.8112 76.265 60.6807 76.2804 60.5532 76.3104C60.4288 76.3377 60.3097 76.3852 60.2006 76.451C60.0981 76.512 60.0108 76.5957 59.9453 76.6956C59.879 76.805 59.8462 76.9315 59.851 77.0595C59.8437 77.1649 59.8636 77.2705 59.9089 77.3659C59.9542 77.4613 60.0232 77.5432 60.1094 77.6037C60.3503 77.748 60.6132 77.8513 60.8875 77.9095L61.6413 78.0899C61.9884 78.163 62.3059 78.3386 62.5532 78.5944C62.6741 78.7198 62.769 78.8682 62.8321 79.031C62.8953 79.1937 62.9255 79.3676 62.9209 79.5422C62.9279 79.7879 62.873 80.0313 62.7614 80.25C62.6499 80.4686 62.4852 80.6553 62.2826 80.7928C62.0821 80.929 61.8586 81.0274 61.6231 81.0832C61.3763 81.1434 61.1232 81.1731 60.8693 81.1719C60.4793 81.1755 60.093 81.0963 59.7355 80.9395C59.3661 80.7678 59.0552 80.4902 58.8419 80.1415L59.4833 79.7012C59.6259 79.9354 59.8204 80.1332 60.0516 80.2791C60.2986 80.4267 60.5821 80.501 60.8693 80.4931C61.0207 80.4926 61.1715 80.4752 61.3191 80.4411C61.461 80.4121 61.5968 80.3583 61.7203 80.2822C61.8342 80.2134 61.9309 80.1192 62.003 80.007C62.0745 79.887 62.1114 79.7494 62.1094 79.6095C62.1171 79.4951 62.0927 79.3808 62.0389 79.2798C61.9852 79.1787 61.9043 79.0948 61.8054 79.0377C61.585 78.9089 61.3461 78.815 61.0972 78.7595L60.3769 78.5883C60.2524 78.5571 60.1296 78.5193 60.0091 78.4751C59.8478 78.4196 59.696 78.3391 59.5592 78.2366C59.4067 78.1266 59.2784 77.9861 59.1823 77.8239C59.0712 77.6333 59.0164 77.4148 59.0243 77.194C59.0188 76.9465 59.0752 76.7015 59.1884 76.4816C59.2919 76.2822 59.4422 76.1111 59.6261 75.9832C59.8158 75.8459 60.0276 75.7425 60.2523 75.6775C60.4871 75.6135 60.7294 75.5816 60.9726 75.5827C61.3254 75.5784 61.675 75.6515 61.9969 75.7967C62.3194 75.9485 62.5786 76.2092 62.7294 76.5336L62.082 76.98Z"
        fill="#B55434"
      />
      <path
        d="M67.4225 73.6869C67.4245 73.7607 67.4103 73.8341 67.3809 73.9017C67.3514 73.9694 67.3075 74.0297 67.2523 74.0783C67.1528 74.1763 67.0191 74.2312 66.8799 74.2312C66.7407 74.2312 66.607 74.1763 66.5076 74.0783C66.4546 74.028 66.4124 73.9673 66.3835 73.9C66.3547 73.8327 66.3398 73.7602 66.3398 73.6869C66.3398 73.6136 66.3547 73.5411 66.3835 73.4738C66.4124 73.4065 66.4546 73.3458 66.5076 73.2955C66.607 73.1975 66.7407 73.1426 66.8799 73.1426C67.0191 73.1426 67.1528 73.1975 67.2523 73.2955C67.3072 73.3444 67.3509 73.4047 67.3803 73.4723C67.4097 73.5399 67.4241 73.6131 67.4225 73.6869ZM67.2523 81.025H66.5076V75.7202H67.2523V81.025Z"
        fill="#B55434"
      />
      <path
        d="M76.6262 78.3743C76.63 78.7545 76.5587 79.1318 76.4164 79.4841C76.2841 79.816 76.086 80.1174 75.8341 80.3697C75.5822 80.622 75.2819 80.8201 74.9514 80.9518C74.2259 81.2291 73.4246 81.2291 72.6991 80.9518C72.369 80.8174 72.0685 80.619 71.8146 80.3678C71.5647 80.1149 71.3673 79.8146 71.2341 79.4841C70.9586 78.7691 70.9586 77.9763 71.2341 77.2613C71.3673 76.9309 71.5647 76.6305 71.8146 76.3777C72.0685 76.1265 72.369 75.928 72.6991 75.7937C73.4246 75.5164 74.2259 75.5164 74.9514 75.7937C75.2819 75.9254 75.5822 76.1235 75.8341 76.3758C76.086 76.6281 76.2841 76.9294 76.4164 77.2613C76.5584 77.6148 76.6297 77.993 76.6262 78.3743ZM75.8146 78.3743C75.8159 78.092 75.7686 77.8117 75.6748 77.5457C75.5862 77.2981 75.4498 77.0705 75.2736 76.876C75.0972 76.686 74.8841 76.5341 74.6474 76.4296C74.1169 76.2097 73.5214 76.2097 72.9909 76.4296C72.7596 76.5343 72.5526 76.6863 72.383 76.876C72.2098 77.0716 72.0756 77.299 71.9879 77.5457C71.7969 78.0803 71.7969 78.6651 71.9879 79.1998C72.0756 79.4465 72.2098 79.6738 72.383 79.8694C72.552 80.0598 72.7592 80.2119 72.9909 80.3158C73.5214 80.5358 74.1169 80.5358 74.6474 80.3158C74.8847 80.2124 75.098 80.0604 75.2736 79.8694C75.4541 79.673 75.5936 79.4422 75.6839 79.1906C75.7732 78.9279 75.8174 78.6519 75.8146 78.3743Z"
        fill="#B55434"
      />
      <path
        d="M82.9574 76.4021H81.4377V79.5299C81.4336 79.6917 81.452 79.8533 81.4924 80.01C81.521 80.1182 81.5752 80.2179 81.6504 80.3005C81.7182 80.3675 81.8019 80.4159 81.8936 80.4411C81.9926 80.4685 82.0949 80.4819 82.1975 80.4809C82.3313 80.4806 82.4644 80.461 82.5927 80.4228C82.7224 80.3882 82.8478 80.339 82.9665 80.276L83 80.967C82.6832 81.1028 82.3411 81.1685 81.9969 81.1596C81.8464 81.1587 81.6964 81.1402 81.5501 81.1046C81.395 81.0648 81.2507 80.9906 81.1276 80.8875C80.995 80.7701 80.891 80.6237 80.8237 80.4594C80.7316 80.2327 80.6891 79.9887 80.6991 79.744V76.4021H79.5836V75.7202H80.6991V74.2251H81.4437V75.7202H82.9635L82.9574 76.4021Z"
        fill="#B55434"
      />
      <path
        d="M86.2249 72.4641H86.9665V76.5765H86.9908C87.0719 76.4295 87.1743 76.2955 87.2948 76.179C87.4231 76.0593 87.5661 75.9565 87.7203 75.8733C87.8787 75.785 88.0471 75.7162 88.2218 75.6684C88.393 75.6203 88.5699 75.5956 88.7477 75.595C89.0256 75.5678 89.3061 75.6018 89.5696 75.6948C89.8331 75.7877 90.0733 75.9373 90.2735 76.1332C90.6189 76.5641 90.7914 77.1095 90.7568 77.662V81.0253H90.0121V78.09C90.0409 77.6186 89.9355 77.1487 89.7082 76.7355C89.5075 76.4298 89.1306 76.2646 88.5835 76.2646C88.4814 76.2743 88.38 76.2896 88.2796 76.3105C88.0815 76.3521 87.8948 76.4366 87.7325 76.5582C87.5186 76.7215 87.3432 76.9303 87.2188 77.1697C87.0519 77.5223 86.9747 77.911 86.9939 78.301V81.0253H86.2522L86.2249 72.4641Z"
        fill="#B55434"
      />
      <path
        d="M95.2583 78.5791C95.2809 78.8376 95.3456 79.0907 95.4497 79.3282C95.6491 79.7816 96.0119 80.1421 96.4649 80.3372C96.6964 80.4364 96.9458 80.4864 97.1975 80.4839C97.5605 80.494 97.9186 80.3973 98.2279 80.2057C98.4924 80.0449 98.7178 79.8265 98.8874 79.5667L99.4619 80.0406C99.1876 80.4074 98.8301 80.703 98.4193 80.9028C98.0374 81.0766 97.6227 81.1652 97.2035 81.1627C96.8308 81.1659 96.461 81.0952 96.1154 80.9548C95.7917 80.8202 95.4982 80.6216 95.2522 80.3708C95.0099 80.1142 94.8172 79.8146 94.6838 79.4872C94.4101 78.7718 94.4101 77.9797 94.6838 77.2643C94.8116 76.9355 95.0027 76.6352 95.2461 76.3807C95.491 76.1262 95.7851 75.9247 96.1102 75.7885C96.4352 75.6523 96.7845 75.5844 97.1367 75.5888C97.5054 75.5822 97.8713 75.6552 98.2096 75.8028C98.511 75.9354 98.7812 76.1302 99.0029 76.3746C99.2197 76.6195 99.386 76.9053 99.4923 77.2154C99.6067 77.5446 99.6642 77.891 99.6625 78.2397V78.5791H95.2583ZM98.8418 77.9676C98.8581 77.5162 98.6964 77.0766 98.392 76.7445C98.2254 76.5819 98.0269 76.456 97.8091 76.3749C97.5914 76.2939 97.3593 76.2594 97.1275 76.2737C96.8883 76.2744 96.6517 76.3233 96.4315 76.4174C96.2126 76.5063 96.0117 76.6348 95.8388 76.7965C95.6719 76.9513 95.5348 77.1358 95.4346 77.3408C95.3337 77.5363 95.2816 77.7534 95.2826 77.9737L98.8418 77.9676Z"
        fill="#B55434"
      />
      <path
        d="M103.343 77.3745C103.343 77.1421 103.343 76.9036 103.325 76.6621C103.307 76.4205 103.307 76.1056 103.298 75.7203H104.03V76.7079H104.055C104.11 76.5593 104.185 76.4193 104.279 76.2921C104.38 76.1576 104.498 76.0382 104.632 75.9374C104.779 75.8261 104.944 75.7394 105.118 75.6806C105.32 75.6139 105.532 75.5819 105.745 75.5858C105.93 75.5823 106.115 75.605 106.295 75.6531L106.149 76.3899C106.01 76.3461 105.865 76.3264 105.72 76.3318C105.466 76.3244 105.214 76.3812 104.988 76.497C104.793 76.6013 104.62 76.7437 104.48 76.9158C104.229 77.2332 104.091 77.6262 104.088 78.0318V81.0252H103.343V77.3745Z"
        fill="#B55434"
      />
      <path
        d="M112.632 77.7719V77.6374C112.632 76.7201 112.179 76.2645 111.28 76.2645C110.685 76.2538 110.108 76.4726 109.669 76.876L109.219 76.344C109.714 75.8303 110.456 75.5735 111.45 75.5735C111.699 75.5723 111.948 75.6105 112.185 75.6866C112.413 75.7547 112.624 75.872 112.802 76.0305C112.98 76.1889 113.121 76.3847 113.216 76.6039C113.328 76.8685 113.382 77.1542 113.374 77.4417V79.8113C113.374 80.0131 113.374 80.2271 113.404 80.4503C113.435 80.6735 113.444 80.857 113.465 81.0007H112.745C112.723 80.8722 112.705 80.7316 112.693 80.5818C112.681 80.432 112.678 80.276 112.678 80.1384H112.654C112.471 80.4597 112.204 80.7242 111.881 80.9028C111.544 81.0678 111.171 81.1476 110.796 81.1352C110.567 81.1363 110.339 81.1044 110.119 81.0404C109.908 80.9764 109.711 80.8727 109.538 80.7347C109.371 80.6019 109.233 80.4352 109.134 80.2455C109.029 80.0371 108.977 79.8061 108.982 79.5728C108.96 79.2044 109.078 78.8414 109.313 78.5577C109.543 78.3082 109.83 78.1193 110.149 78.0073C110.494 77.8848 110.854 77.8088 111.219 77.7811C111.596 77.7505 111.945 77.7352 112.261 77.7352L112.632 77.7719ZM112.249 78.3834C112.061 78.3834 111.827 78.3834 111.544 78.414C111.27 78.4317 110.998 78.4768 110.733 78.5485C110.491 78.6132 110.264 78.7265 110.067 78.8818C109.978 78.9564 109.908 79.0501 109.861 79.156C109.813 79.2619 109.79 79.3772 109.793 79.4933C109.788 79.65 109.825 79.8052 109.9 79.9427C109.97 80.0663 110.069 80.1712 110.188 80.2485C110.31 80.3285 110.445 80.3855 110.587 80.4167C110.733 80.4509 110.883 80.4683 111.033 80.4686C111.274 80.4737 111.513 80.4278 111.736 80.3341C111.928 80.2493 112.099 80.123 112.237 79.9642C112.375 79.8062 112.479 79.6207 112.541 79.4199C112.607 79.2049 112.639 78.9812 112.638 78.7564V78.3712L112.249 78.3834Z"
        fill="#B55434"
      />
      <path
        d="M117.353 75.7203H118.094V76.6039H118.119C118.232 76.4242 118.375 76.265 118.541 76.133C118.701 76.0115 118.874 75.9089 119.058 75.8273C119.236 75.7443 119.424 75.6827 119.617 75.6438C119.797 75.6083 119.98 75.5898 120.164 75.5888C120.551 75.5846 120.935 75.6552 121.295 75.7967C121.628 75.9291 121.932 76.1265 122.188 76.3776C122.438 76.6305 122.636 76.9309 122.769 77.2613C123.044 77.9763 123.044 78.7691 122.769 79.4841C122.636 79.8145 122.438 80.1149 122.188 80.3678C121.935 80.619 121.634 80.8174 121.304 80.9518C120.944 81.0933 120.56 81.1639 120.173 81.1597C119.99 81.1586 119.806 81.1402 119.626 81.1046C119.434 81.0654 119.246 81.0049 119.067 80.9242C118.884 80.8414 118.711 80.7389 118.55 80.6185C118.382 80.4889 118.238 80.3317 118.122 80.1537H118.097V84.2998H117.356L117.353 75.7203ZM122.164 78.3742C122.166 78.0917 122.118 77.8111 122.021 77.5456C121.934 77.2982 121.798 77.0707 121.623 76.876C121.446 76.6867 121.233 76.5349 120.997 76.4296C120.736 76.316 120.454 76.2598 120.17 76.2645C119.889 76.2594 119.61 76.3109 119.349 76.4159C119.088 76.521 118.851 76.6774 118.65 76.876C118.461 77.0678 118.311 77.2954 118.21 77.5456C117.999 78.0767 117.999 78.6687 118.21 79.1998C118.311 79.45 118.461 79.6776 118.65 79.8694C118.841 80.0605 119.067 80.2121 119.316 80.3158C119.584 80.4281 119.871 80.4843 120.161 80.4809C120.445 80.4856 120.727 80.4294 120.988 80.3158C121.225 80.2116 121.438 80.0597 121.614 79.8694C121.789 79.6747 121.925 79.4472 122.012 79.1998C122.111 78.9359 122.163 78.6563 122.164 78.3742Z"
        fill="#B55434"
      />
      <path
        d="M128.45 80.1078L130.061 75.7202H130.973L128.334 82.4071C128.202 82.7717 127.995 83.1041 127.726 83.3824C127.583 83.5105 127.417 83.6087 127.235 83.6712C127.054 83.7336 126.863 83.7592 126.672 83.7463C126.559 83.7463 126.447 83.7463 126.328 83.7463C126.212 83.7375 126.096 83.7149 125.985 83.679L126.064 82.9513C126.239 83.0378 126.431 83.0808 126.626 83.0767C126.739 83.0812 126.852 83.0614 126.957 83.0187C127.061 82.976 127.156 82.9114 127.234 82.829C127.409 82.6217 127.543 82.3831 127.629 82.1258L128.012 81.1046L125.848 75.7324H126.784L128.45 80.1078Z"
        fill="#B55434"
      />
      <path
        d="M142.836 77.2521H145.371V77.9309H142.836V80.4809H142.161V77.9309H139.626V77.2521H142.161V74.7021H142.836V77.2521Z"
        fill="#B55434"
      />
      <path
        d="M154.711 75.7203H155.456V76.6039H155.477C155.595 76.4273 155.739 76.2704 155.906 76.1392C156.066 76.0176 156.239 75.915 156.422 75.8334C156.601 75.7504 156.789 75.6888 156.982 75.65C157.162 75.6144 157.345 75.596 157.529 75.5949C157.916 75.5904 158.299 75.661 158.66 75.8028C158.99 75.9346 159.29 76.1326 159.542 76.3849C159.794 76.6373 159.992 76.9386 160.125 77.2705C160.404 77.9847 160.404 78.7791 160.125 79.4933C159.992 79.8252 159.794 80.1265 159.542 80.3788C159.29 80.6312 158.99 80.8292 158.66 80.9609C158.299 81.1028 157.916 81.1734 157.529 81.1688C157.345 81.1678 157.162 81.1494 156.982 81.1138C156.789 81.0746 156.602 81.0141 156.422 80.9334C156.24 80.8506 156.066 80.7481 155.906 80.6277C155.739 80.4964 155.595 80.3395 155.477 80.1629H155.456V84.309H154.711V75.7203ZM159.523 78.3742C159.525 78.0917 159.476 77.8111 159.38 77.5456C159.295 77.3003 159.16 77.0752 158.984 76.8848C158.809 76.6943 158.596 76.5424 158.359 76.4388C158.098 76.3252 157.816 76.2689 157.532 76.2737C157.242 76.2703 156.954 76.3264 156.687 76.4388C156.439 76.5443 156.213 76.6958 156.021 76.8852C155.832 77.077 155.682 77.3046 155.58 77.5548C155.374 78.0866 155.374 78.6772 155.58 79.209C155.682 79.4592 155.832 79.6868 156.021 79.8786C156.213 80.069 156.439 80.2206 156.687 80.325C156.954 80.4373 157.242 80.4935 157.532 80.4901C157.816 80.4948 158.098 80.4385 158.359 80.325C158.595 80.2208 158.809 80.0689 158.985 79.8786C159.161 79.6847 159.297 79.4569 159.383 79.209C159.475 78.9407 159.518 78.6579 159.511 78.3742H159.523Z"
        fill="#B55434"
      />
      <path
        d="M164.918 73.6871C164.92 73.7609 164.906 73.8342 164.876 73.9019C164.847 73.9695 164.803 74.0298 164.748 74.0784C164.648 74.175 164.515 74.2297 164.377 74.2313C164.239 74.2291 164.106 74.1745 164.006 74.0784C163.952 74.0286 163.909 73.9681 163.88 73.9007C163.851 73.8334 163.835 73.7606 163.835 73.6871C163.835 73.6135 163.851 73.5408 163.88 73.4734C163.909 73.406 163.952 73.3455 164.006 73.2957C164.106 73.1997 164.239 73.1451 164.377 73.1428C164.515 73.1445 164.648 73.1991 164.748 73.2957C164.803 73.3446 164.846 73.4049 164.876 73.4725C164.905 73.5401 164.92 73.6133 164.918 73.6871ZM164.742 81.0252H164V75.7203H164.742V81.0252Z"
        fill="#B55434"
      />
      <path
        d="M169.705 81.0253H168.963V72.4641H169.705V81.0253Z"
        fill="#B55434"
      />
      <path
        d="M177.131 77.7719V77.6374C177.131 76.7221 176.68 76.2645 175.778 76.2645C175.183 76.2538 174.607 76.4726 174.167 76.876L173.717 76.344C174.212 75.8304 174.955 75.5735 175.948 75.5735C176.198 75.5721 176.446 75.6102 176.684 75.6867C176.908 75.7577 177.115 75.8754 177.292 76.0322C177.469 76.196 177.611 76.3948 177.708 76.6161C177.819 76.8812 177.873 77.1666 177.866 77.4539V79.8235C177.866 80.0253 177.866 80.2394 177.894 80.4626C177.921 80.6858 177.933 80.8692 177.954 81.0129H177.234C177.213 80.8845 177.194 80.7438 177.182 80.594C177.17 80.4442 177.167 80.2883 177.167 80.1507H177.143C176.961 80.4727 176.694 80.7373 176.371 80.9151C176.033 81.0801 175.661 81.1598 175.286 81.1474C175.056 81.1486 174.828 81.1166 174.608 81.0527C174.397 80.988 174.2 80.8843 174.027 80.7469C173.86 80.6142 173.722 80.4475 173.623 80.2577C173.518 80.0493 173.466 79.8184 173.471 79.585C173.449 79.2166 173.568 78.8537 173.802 78.5699C174.031 78.3212 174.317 78.1323 174.635 78.0196C174.982 77.8977 175.342 77.8216 175.708 77.7933C176.085 77.7627 176.435 77.7474 176.748 77.7474L177.131 77.7719ZM176.748 78.3834C176.559 78.3834 176.325 78.3834 176.042 78.414C175.768 78.4317 175.496 78.4768 175.231 78.5485C174.99 78.6128 174.764 78.7263 174.568 78.8818C174.479 78.9556 174.407 79.0492 174.359 79.1552C174.311 79.2612 174.288 79.3769 174.292 79.4933C174.288 79.6498 174.325 79.8045 174.398 79.9428C174.474 80.0676 174.578 80.1724 174.702 80.2485C174.824 80.3285 174.958 80.3855 175.1 80.4167C175.247 80.4509 175.397 80.4683 175.547 80.4687C175.788 80.474 176.027 80.4282 176.249 80.3341C176.441 80.2493 176.613 80.123 176.751 79.9642C176.89 79.807 176.994 79.6213 177.055 79.4199C177.12 79.205 177.153 78.9813 177.152 78.7564V78.3712L176.748 78.3834Z"
        fill="#B55434"
      />
      <path
        d="M184.578 76.4021H183.058V79.5299C183.055 79.6919 183.074 79.8535 183.116 80.01C183.144 80.1182 183.198 80.2179 183.274 80.3005C183.341 80.3675 183.425 80.4159 183.517 80.4411C183.616 80.4688 183.718 80.4822 183.821 80.4809C183.954 80.4806 184.086 80.461 184.213 80.4228C184.343 80.3888 184.468 80.3395 184.587 80.276L184.62 80.967C184.303 81.1028 183.961 81.1685 183.617 81.1596C183.467 81.1587 183.317 81.1402 183.17 81.1046C183.015 81.0648 182.871 80.9906 182.748 80.8875C182.616 80.7692 182.512 80.623 182.444 80.4594C182.354 80.232 182.313 79.9884 182.322 79.744V76.4021H181.207V75.7202H182.322V74.2251H183.064V75.7202H184.584L184.578 76.4021Z"
        fill="#B55434"
      />
      <path
        d="M188.362 78.5792C188.384 78.8377 188.449 79.0908 188.553 79.3283C188.753 79.7817 189.115 80.1422 189.568 80.3373C189.8 80.4365 190.049 80.4865 190.301 80.484C190.664 80.4941 191.022 80.3974 191.331 80.2058C191.596 80.045 191.821 79.8266 191.991 79.5668L192.565 80.0407C192.291 80.4075 191.934 80.7031 191.523 80.9029C191.141 81.0767 190.726 81.1653 190.307 81.1628C189.934 81.166 189.565 81.0953 189.219 80.9549C188.895 80.8203 188.602 80.6217 188.356 80.3709C188.112 80.1176 187.917 79.8211 187.781 79.4964C187.508 78.7811 187.508 77.989 187.781 77.2736C187.909 76.9447 188.1 76.6444 188.344 76.39C188.588 76.1355 188.882 75.934 189.208 75.7978C189.533 75.6616 189.882 75.5937 190.234 75.5981C190.603 75.5915 190.969 75.6645 191.307 75.8121C191.608 75.9447 191.879 76.1395 192.1 76.3839C192.317 76.6288 192.483 76.9146 192.59 77.2247C192.704 77.5539 192.762 77.9003 192.76 78.249V78.5792H188.362ZM191.945 77.9677C191.962 77.5163 191.8 77.0767 191.495 76.7446C191.329 76.582 191.13 76.4561 190.913 76.375C190.695 76.294 190.463 76.2595 190.231 76.2738C189.992 76.2745 189.755 76.3234 189.535 76.4175C189.311 76.5047 189.105 76.6333 188.927 76.7966C188.76 76.9514 188.623 77.1359 188.523 77.3409C188.422 77.5364 188.37 77.7535 188.371 77.9738L191.945 77.9677Z"
        fill="#B55434"
      />
      <path
        d="M199.155 76.98C199.052 76.7699 198.897 76.5899 198.705 76.4571C198.501 76.3241 198.261 76.2569 198.018 76.2645C197.887 76.2648 197.757 76.2802 197.629 76.3104C197.501 76.3333 197.378 76.3767 197.264 76.4388C197.163 76.5007 197.077 76.5842 197.012 76.6834C196.945 76.7924 196.911 76.919 196.915 77.0472C196.907 77.1527 196.927 77.2582 196.973 77.3536C197.018 77.449 197.087 77.531 197.173 77.5915C197.414 77.7358 197.677 77.8391 197.951 77.8972L198.705 78.0776C199.052 78.1513 199.369 78.3268 199.617 78.5821C199.737 78.7078 199.832 78.8563 199.894 79.0191C199.957 79.1818 199.987 79.3555 199.982 79.53C199.989 79.7875 199.929 80.0425 199.808 80.2699C199.695 80.4733 199.537 80.648 199.346 80.7805C199.146 80.9162 198.922 81.0146 198.687 81.071C198.44 81.1309 198.187 81.1607 197.933 81.1596C197.543 81.1636 197.157 81.0844 196.799 80.9273C196.429 80.7558 196.117 80.4782 195.903 80.1293L196.547 79.689C196.689 79.9238 196.884 80.1217 197.115 80.2668C197.362 80.4145 197.646 80.4887 197.933 80.4809C198.084 80.48 198.235 80.4626 198.383 80.4289C198.524 80.3992 198.658 80.3455 198.781 80.2699C198.896 80.202 198.993 80.1077 199.064 79.9947C199.137 79.8754 199.174 79.7373 199.17 79.5972C199.178 79.4828 199.153 79.3686 199.1 79.2675C199.046 79.1664 198.965 79.0825 198.866 79.0255C198.645 78.8961 198.405 78.8022 198.155 78.7472L197.435 78.576C197.311 78.5448 197.189 78.507 197.07 78.4629C196.908 78.4067 196.755 78.3263 196.617 78.2244C196.465 78.1135 196.337 77.9732 196.24 77.8116C196.13 77.6205 196.076 77.4024 196.082 77.1818C196.076 76.9342 196.133 76.6892 196.246 76.4694C196.351 76.2698 196.502 76.0988 196.687 75.971C196.871 75.8353 197.076 75.7319 197.295 75.6652C197.531 75.6013 197.774 75.5694 198.018 75.5704C198.371 75.5669 198.72 75.6399 199.042 75.7845C199.365 75.9362 199.624 76.197 199.775 76.5213L199.155 76.98Z"
        fill="#B55434"
      />
    </svg>
  )
}

export default FullColorLogo
